import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';

const Login = () => {
  useEffect(() => {
    document.title = 'What is 9AG';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };


  return (
    <>
      <Header />
      <Banner className="ninearrowsgeeks-page" />

<div className='sententce-container'>

<h1>Nine Arrows Geeks</h1>

Nine Arrows Geeks is a premium online learning platform specializing in the Thai language. Symbolized by the auspicious number "9" in Thai culture, our mission is to empower learners to acquire knowledge as a weapon for life and achieve progress and advancement.

<h2>9AG's Vision</h2>
Nine Arrows Geeks envisions exploring the beauty and depth of the Thai language and providing it to learners worldwide. 9AG aims to bridge people with different backgrounds by fostering cultural understanding through language learning.

<h2>Why Choose Nine Arrows Geeks?</h2>
<li><b>High-Quality Content:</b> Nine Arrows Geeks offers rich and high-quality learning content. You can learn accurate pronunciation from native speakers and practical phrases.</li>

<li><b>Flexible Learning Styles:</b> 9AG provides courses and lessons tailored to your learning style. Learn at your own pace and enhance your comprehension.</li>

<li><b>Cultural Understanding:</b> In addition to language, you'll also learn about Thai culture and history. This enriches your language comprehension and deepens your communication.</li>

<li><b>Expert Support:</b> Nine Arrows Geeks' experts provide support to help learners continue progressing. They offer prompt answers to questions and maximize the effectiveness of your learning.</li>

<h2>Nine Arrows Geeks' Courses</h2>
<li><b>From Beginners to Advanced:</b> We offer courses for a wide range of levels, from beginners to advanced learners. You can choose the course that best suits your skills.</li>

<li><b>Enhance Conversation Skills:</b> We have courses that improve practical conversation skills, from everyday conversations to business communication.</li>

<li><b>Grammar and Pronunciation:</b> We provide courses to master accurate grammar and pronunciation, enabling you to speak the language with confidence.</li>

<h2>Nine Arrows Geeks' Community</h2>
Nine Arrows Geeks values the community of learners. We promote interaction among learners and provide various resources to make language learning enjoyable and fulfilling.

<h2>Contact and Support</h2>
To enhance your learning experience with Nine Arrows Geeks, we always listen to our customers. If you have any questions, suggestions, or need support, please feel free to contact us.
Contact: ninearrowsgeeks@gmail.com
The 9AG support team will be happy to assist you.
Nine Arrows Geeks is the ideal place to start your journey of language learning.
Let's improve your language skills and explore new cultures together. With Nine Arrows Geeks, let's open new doors.

<br></br>
<br></br>

</div>
      <div className="center-thai">
        <div className="button-group-home">
          <Link to="/" className="button">HOME</Link>
        </div>
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </>
  );
};

export default Login;
