import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Tones = () => {
  useEffect(() => {
    document.title = 'Thai Reading';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
    <html>
    <body>
    <Header />
    <Banner className="reading-page"/>

    <div className="center-thai">
      <h1 className="title-each-page">Thai Reading</h1>
      <div className="button-group">
      <Link to="/reading_quiz_1" className="button">Quesions①</Link>
      <Link to="/reading_quiz_2" className="button">Quesions②</Link>
      </div>      
      <div className="button-group">
      <Link to="/reading_quiz_3" className="button">Quesions③</Link>
      <Link to="/reading_quiz_4" className="button">Quesions④</Link>
      </div>      
      <div className="button-group">
      <Link to="/reading_quiz_5" className="button">Quesions⑤</Link>
      <Link to="/reading_quiz_6" className="button">Quesions⑥</Link>
      </div>      
      <div className="button-group">
      <Link to="/reading_quiz_7" className="button">Quesions⑦</Link>
      <Link to="/reading_quiz_8" className="button">Quesions⑧</Link>
      </div>      
      <div className="button-group">
      <Link to="/reading_quiz_9" className="button">Quesions⑨</Link>
      <Link to="/reading_quiz_10" className="button">Quesions⑩</Link>
      </div>      
      <br></br>

      <div className="button-group-home">
      <Link to="/" className="button">HOME</Link>
      </div>

    </div>
    {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </body>
    </html>
    </>
  );
};

export default Tones;