import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';

const Login = () => {
  useEffect(() => {
    document.title = 'Password Change';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);
  const [userData, setUserData] = useState(null);
  
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const [newPassword, setNewPassword] = useState('');
  const [reNewPassword, setReNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // ローカルストレージからJWTアクセストークンを取得
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const handleChangePassword = () => {
    if (newPassword === reNewPassword) {
      axios
        .post(
          //Local
          // 'http://localhost:8000/api/auth/users/set_password/',
          //Production
          'https://bitmerica.net/api/auth/users/set_password/',
          {
            new_password: newPassword,
            re_new_password: reNewPassword,
            current_password: currentPassword,
          },
          {
            headers: {
              Authorization: `JWT ${accessToken}`, // ローカルストレージから取得したJWTアクセストークンを設定
              // 'Authorization': `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk2NDA4OTI0LCJpYXQiOjE2OTY0MDUzMjQsImp0aSI6ImUxYzI2ODY4NmFmYjRlMDE5ZWZlYmVlZTUwYmU1Y2VkIiwidXNlcl9pZCI6MTF9.XlEfCWiBGmig_is6alGR5QsvRKFOpUf8WvbmuX_bN94`, 
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          console.log('Password Changed');
          alert('Password Changed');
          window.location.href = '/'; // ログアウト後にホームページにリダイレクト
          // パスワードが変更されたら"/"ページにリダイレクト
          // navigate.push('/home');
        })
        .catch((error) => {
          console.error('Error can not changed password:', error);
          console.log(accessToken)
          setErrorMessage('Error can not changed password');
        });
    } else {
      setErrorMessage('not match with new password');
    }
  };

  useEffect(() => {
    // ローカルストレージからJWTアクセストークンを取得
    const accessToken = localStorage.getItem('accessToken');

    // アクセストークンが存在する場合、DjangoのAPIからユーザーデータを取得
    if (accessToken) {
      //Local
      // axios.get('http://localhost:8000/api/auth/users/me/', {
      //Production
      axios.get('https://bitmerica.net/api/auth/users/me/', {
        headers: {
          'Authorization': `JWT ${accessToken}`,
        },
      })
      .then(response => {
        setUserData(response.data);
      })
      .catch(error => {
        console.error('failed to get user data', error);
      });
    }
  }, []);

  const resetPassword = () => {
    if (userData && userData.email) {
      const accessToken = localStorage.getItem('accessToken');
      
      //Local
      // axios.post('http://localhost:8000/api/auth/users/reset_password/', {
      //Production
      axios.post('https://bitmerica.net/api/auth/users/reset_password/', {
        email: userData.email,
      }, {
        headers: {
          'Authorization': `JWT ${accessToken}`,
        },
      })
      .then(response => {
        // パスワードリセットリクエストが成功した場合の処理
        console.log('Password reset request succeeded!');
        alert('Your password reset request was successful. Please check the email sent to you to change your password.');
        window.location.href = '/'; // ホームページにリダイレクト
      })
      .catch(error => {
        // エラーハンドリング
        console.error('Password reset request failed', error);
        alert('Error: Password reset request failed ' + error.message);
      });
    } else {
      alert('Insufficient user data.');
    }
  };
  

  return (
    <>
      <Header />
      <Banner className="user-page" />

      <div className="input-container">
      <h2>Password Change</h2>
      <div className="input-field">
        <label>New Password:</label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <div className="input-field">
        <label>New Password（Again）:</label>
        <input
          type="password"
          value={reNewPassword}
          onChange={(e) => setReNewPassword(e.target.value)}
        />
      </div>
      <div className="input-field">
        <label>Current Password:</label>
        <input
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
      </div>
      <div className="input-field">
      <button onClick={handleChangePassword}>Change Password</button>
      </div>
      {errorMessage && <p>{errorMessage}</p>}
      <p>If you forget your current password, we will reset it for you by email</p>
      <p>Please click<button onClick={resetPassword}>Password reset</button></p>

    </div>

      <div className="center-thai">
        <div className="button-group-home">
          <Link to="/" className="button">HOME</Link>
        </div>
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </>
  );
};

export default Login;
