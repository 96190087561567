import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Words = () => {
  useEffect(() => {
    document.title = '身体に関する言葉';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <html>
      <body>
      <Header />
      <Banner className="words-page"/>
  
      <div className="center-thai">
        <h1 className="title-each-page">身体に関する言葉</h1>
        <div className="button-group">
          <Link to="/ja/face" className="button">顔</Link>
          <Link to="/ja/face_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/expression_of_body" className="button">全身</Link>
          <Link to="/ja/expression_of_body_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/health" className="button">健康</Link>
          <Link to="/ja/health_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/lower_body" className="button">下半身</Link>
          <Link to="/ja/lower_body_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/phy_phenomenon" className="button">生理現象</Link>
          <Link to="/ja/phy_phenomenon_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/upper_body" className="button">上半身</Link>
          <Link to="/ja/upper_body_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group-home">
        <Link to="/ja/words" className="button">戻る</Link>
        </div>
  
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

      </body>
      </html>
    </>
    );
  };

export default Words;