import React, { useState, useEffect } from "react";
import Listening_Basic from './basic_listening'; // Words コンポーネントをインポート
import Header from '../header';
import Footer from '../footer';
import '../App.css';


export const Listening_1 = () => {
  useEffect(() => {
    document.title = 'Thai Listening';
  }, []);


  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <body>
      <Header />
      <div className="center-content3">
      <Listening_Basic dataPath='/json/listening/greeting.json' />
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

      </div>
      </body>
    </div>
  );
};

export default Listening_1;
