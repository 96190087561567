import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';

const Login = () => {
  useEffect(() => {
    document.title = 'Privacy Policy';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };


  return (
    <>
      <Header />
      <Banner className="privacypolicy-page" />

<div className='sententce-container'>

<h1>Privacy Policy</h1>

Nine Arrows Geeks (hereinafter referred to as "the Site") establishes this Privacy Policy (hereinafter referred to as "this Policy") as follows regarding the handling of users' personal information in the services provided on this website (hereinafter referred to as "the Services").

<h2>Article 1 (Personal Information)</h2>

"Personal Information" refers to information about a living individual, which is defined in the Personal Information Protection Act, and includes information that can identify a specific individual by name, date of birth, address, telephone number, contact information, and other descriptions contained in the information, as well as data such as appearance, fingerprints, voiceprints, and health insurance certificate numbers that can identify a specific individual from the information itself (personal identification information).

<h2>Article 2 (Methods of Collecting Personal Information)</h2>

The Site may ask users to provide personal information such as name, date of birth, address, phone number, email address, bank account number, credit card number, driver's license number, and other personal information when registering for use. Additionally, the Site may collect transaction records and payment information, including personal information, between users and business partners, etc. (including information providers, advertisers, and ad distributors; hereinafter, "Partners").

<h2>Article 3 (Purpose of Collecting and Using Personal Information)</h2>

The purposes for which the Site collects and uses personal information are as follows:

<li>For the provision and operation of the Site's services</li>
<li>To respond to inquiries from users (including verifying the identity of the user)</li>
<li>To send emails regarding new features, updates, campaigns, etc., of the services being used by the user and other services provided by the Site</li>
<li>For maintenance, important notices, and other necessary communications</li>
<li>To identify users who violate the Terms of Use or who attempt to use the services for fraudulent or unjust purposes and to refuse their use</li>
<li>For users to view, change, delete, and check the usage status of their registration information</li>
<li>To bill users for paid services</li>
<li>For other purposes related to the above purposes</li>

<h2>Article 4 (Change of Purpose of Use)</h2>

The Site may change the purpose of using personal information only when it is reasonably related to the original purpose. If the purpose of use is changed, the Site will notify the user of the new purpose or make it public on this website by the method prescribed by the Site.

<h2>Article 5 (Provision of Personal Information to Third Parties)</h2>

The Site will not provide personal information to third parties without the prior consent of the user, except in the following cases:
<li>When required by laws and regulations</li>
<li>When it is necessary to protect the life, body, or property of a person, and it is difficult to obtain the consent of the user</li>
<li>When it is particularly necessary to improve public health or promote the sound growth of children, and it is difficult to obtain the consent of the user</li>
<li>When it is necessary to cooperate with a national institution or a local public entity or a person entrusted by it in executing the affairs prescribed by laws and regulations, and obtaining the consent of the user is likely to impede the execution of the affairs concerned</li>
<li>When personal information is provided as a result of a business succession due to a merger or other reasons</li>
<li>When information other than personal information, such as history information and characteristic information, is provided in a state where the specific individual cannot be identified</li>
<li>When the Site entrusts all or part of the handling of personal information within the scope necessary to achieve the purpose of use</li>

<h2>Article 6 (Disclosure of Personal Information)</h2>

When requested by the user to disclose their personal information, the Site will promptly disclose it to the user. However, the Site may not disclose all or part of the information if it falls under any of the following circumstances, and if disclosure is not made, the user will be promptly notified. In addition, a fee of 1,000 yen per case will be charged for personal information disclosure.
<li>When there is a risk of harming the life, body, property, or other rights and interests of the individual or a third party</li>
<li>When there is a risk of significantly hindering the proper implementation of the Site's business</li>
<li>When it would violate laws and regulations</li>
Notwithstanding the provisions of the preceding paragraph, information other than personal information, such as history information and characteristic information, will not be disclosed in principle.

<h2>Article 7 (Correction and Deletion of Personal Information)</h2>

<li>If the user's personal information held by the Site is incorrect, the user can request the Site to correct, add, or delete (hereinafter referred to as "correction, etc.") the personal information according to the procedure specified by the Site.</li>
<li>If the Site determines that it is necessary to respond to a request from the user in accordance with the preceding paragraph, the Site will correct the personal information without delay.</li>
<li>If the Site has made a correction pursuant to the provisions of the preceding paragraph, or has decided not to make a correction, the Site will notify the user to that effect without delay.</li>

<h2>Article 8 (Suspension of Use of Personal Information, etc.)</h2>

<li>If the user requests the Site to suspend or erase the use of personal information on the grounds that it is being handled beyond the scope of the purpose of use or that it has been acquired by fraudulent or other improper means, the Site will promptly conduct the necessary investigation.</li>
<li>Based on the results of the investigation conducted in accordance with the provisions of the preceding paragraph, if the Site determines that it is necessary to respond to the request, the Site will suspend or erase the use of the personal information without delay.</li>
<li>If the Site suspends or erases the use of personal information pursuant to the provisions of the preceding paragraph, or decides not to suspend or erase the use, the Site will notify the user to that effect without delay.</li>
<li>Notwithstanding the provisions of the preceding two paragraphs, if the suspension of use, etc. requires a large expense or is otherwise difficult to implement, and it is necessary to take alternative measures to protect the rights and interests of the user, the Site will take such alternative measures.</li>

<h2>Article 9 (Changes to Privacy Policy)</h2>

<li>The Site may change the contents of this Policy without notifying the user except as otherwise provided by laws and regulations. The changed Privacy Policy shall take effect from the time it is posted on this website, unless otherwise specified by the Site.</li>

<br></br>
<br></br>
</div>
      <div className="center-thai">
        <div className="button-group-home">
          <Link to="/" className="button">HOME</Link>
        </div>
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </>
  );
};

export default Login;
