import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Tones = () => {
  useEffect(() => {
    document.title = 'タイ語 声調';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
    <html>
    <body>
    <Header />
    <Banner className="tones-page"/>

    <div className="center-thai">
      <h1 className="title-each-page">タイ語の声調</h1>
      <div className="button-group">
      <Link to="/ja/tones_basics" className="button">声調の基本</Link>
      <Link to="/ja/tones_mark" className="button">声調記号</Link>
      </div>
      <div className="button-group">
      <Link to="/ja/tones_1" className="button">第一声調(平声)の言葉</Link>
      <Link to="/ja/tones_2" className="button">第二声調(低声)の言葉</Link>
      </div>
      <div className="button-group">
      <Link to="/ja/tones_3" className="button">第三声調(下声)の言葉</Link>
      <Link to="/ja/tones_4" className="button">第四声調(高声)の言葉</Link>
      </div>
      <div className="button-group">
      <Link to="/ja/tones_5" className="button">第五声調(上声)の言葉</Link>
      <Link to="/ja/tones_quiz" className="button">声調クイズ</Link>
      </div>
      <br></br>

      <div className="button-group-home">
      <Link to="/ja" className="button">HOME</Link>
      </div>

    </div>
    {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </body>
    </html>
    </>
  );
};

export default Tones;