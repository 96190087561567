import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Tones = () => {
  useEffect(() => {
    document.title = 'Thai Tones';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
    <html>
    <body>
    <Header />
    <Banner className="tones-page"/>

    <div className="center-thai">
      <h1 className="title-each-page">Thai Tones</h1>
      <div className="button-group">
      <Link to="/tones_basics" className="button">Tones Basics</Link>
      <Link to="/tones_mark" className="button">tone mark</Link>
      </div>
      <div className="button-group">
      <Link to="/tones_1" className="button">First tone(Mid)</Link>
      <Link to="/tones_2" className="button">2nd tone(Low)</Link>
      </div>
      <div className="button-group">
      <Link to="/tones_3" className="button">3rd tone(Falling)</Link>
      <Link to="/tones_4" className="button">4th tone(High)</Link>
      </div>
      <div className="button-group">
      <Link to="/tones_5" className="button">5th tone(Rising)</Link>
      <Link to="/tones_quiz" className="button">Tone Quiz</Link>
      </div>
      <br></br>

      <div className="button-group-home">
      <Link to="/" className="button">HOME</Link>
      </div>

    </div>
    {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </body>
    </html>
    </>
  );
};

export default Tones;