import React, { Component } from 'react';

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      currentYear: new Date().getFullYear()
    };
  }

  render() {
    return (
      <footer className='custom-footer'>
        <p>Copyright&copy;{this.state.currentYear} 9AG inc. </p>
      </footer>
    );
  }
}

export default Footer;