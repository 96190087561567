import React, {useState,useEffect} from 'react'
import { GrammarQuiz } from './quiz_sort.js'; // Quiz コンポーネントをインポート
import Header from '../header';
import Footer from '../footer';
import '../App.css';

const Grammar_Quiz_al = () => {
  useEffect(() => {
    document.title = 'Thai Grammar Quiz (Alphabet)';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Quiz コンポーネントを使用
  return (
    <div>
    <body>
    <Header />
    <div className="center-content3">
      <GrammarQuiz quizDataPath='/json/grammar/grammar_quiz_al.json' quizForeign={60}/>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

    </div>
    </body>
    </div>
  );
};

export default Grammar_Quiz_al;