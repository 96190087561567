import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Grammar = () => {
  useEffect(() => {
    document.title = 'タイ語 文法';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    <html>
    <body>
    <Header />
    <Banner className="grammar-page"/>

    <div className="center-thai">
      <h1 className="title-each-page">タイ語の文法</h1>
      <div className="button-group-home">
      <Link to="/ja/grammar_rules" className="button">文法</Link>
      </div>
      <div className="button-group-home">
      <Link to="/ja/grammar_quiz_al" className="button">文法クイズ(Alphabet）</Link>
      </div>
      <div className="button-group-home">
      <Link to="/ja/grammar_quiz_th" className="button">文法クイズ(タイ文字）</Link>
      </div>
      <br></br>
      <div className="button-group-home">
      <Link to="/ja/" className="button">HOME</Link>
      </div>

    </div>
    {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </body>
    </html>
    </>
  );
};

export default Grammar;