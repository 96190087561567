import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';

const Login = () => {
  useEffect(() => {
    document.title = '9AGとは';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };


  return (
    <>
      <Header />
      <Banner className="ninearrowsgeeks-page" />

<div className='sententce-container'>

<h1>Nine Arrows Geeks（ナイン アロー ギークス）</h1>

Nine Arrows Geeksは、タイ語の学習に特化したプレミアムなオンライン学習プラットフォームです。タイで縁起の良い数字である「9」を象徴とし、学習者が人生の武器として知識を習得し、前進と進展を果たすお手伝いをすることをミッションとしています。

<h2>9AGのビジョン</h2>
Nine Arrows Geeksは、タイ語の美しさと深さを探求し、世界中の学習者にそれを提供することをビジョンとしています。9AGは言語を学ぶことを通じて文化を理解し、異なる背景を持つ人々をつなげる架け橋となることを目指しています。

<h2>なぜNine Arrows Geeksを選ぶべきか？</h2>
<li><b>質の高いコンテンツ: </b>Nine Arrows Geeksは、豊富で高品質な学習コンテンツを提供します。ネイティブスピーカーによる正確な発音や実用的なフレーズを学ぶことができます。</li>

<li><b>柔軟な学習スタイル: </b>9AGはあなたの学習スタイルに合わせたコースやレッスンを提供します。自分のペースで学び、理解度を向上させることができます。</li>

<li><b>文化の理解:</b> 言語を学ぶだけでなく、タイの文化や歴史についても学びます。これにより、言語をより豊かに理解し、コミュニケーションを深めることができます。</li>

<li><b>専門的なサポート:</b> Nine Arrows Geeksの専門家は、学習者が進歩し続けるためにサポートします。質問や疑問に対する迅速な回答を提供し、学習の効果を最大化します。</li>

<h2>Nine Arrows Geeksのコース</h2>
<li><b>初心者から上級者まで:</b> 初心者向けから上級者向けまで、幅広いレベルのコースを提供しています。あなたのスキルに合わせて最適なコースを選ぶことができます。</li>

<li><b>会話力向上:</b> 日常会話からビジネスコミュニケーションまで、実用的な会話スキルを向上させるコースがあります。</li>

<li><b>文法と発音:</b> 正確な文法と発音をマスターするためのコースを提供し、自信を持って言語を話すことができるようにサポートします。</li>

<h2>Nine Arrows Geeksのコミュニティ</h2>
Nine Arrows Geeksは、学習者のためのコミュニティを大切にしています。9AGは学習者同士の交流を促進し、言語の学習を楽しく、充実したものにするためにさまざまなリソースを提供しています。

<h2>お問い合わせとサポート</h2>
Nine Arrows Geeksの学習体験を向上させるために、常にお客様の声に耳を傾けています。質問、提案、サポートが必要な場合は、お気軽にお問い合わせください。
問い合わせ：ninearrowsgeeks@gmail.com
9AGのサポートチームがお手伝いさせていただきます。
Nine Arrows Geeksは、言語学習の新しい旅を始めるための理想的な場所です。
あなたの言語スキルを向上させ、新しい文化を探求しましょう。Nine Arrows Geeksとともに、新たな扉を開けましょう。


</div>
<br></br>
<br></br>
      <div className="center-thai">
        <div className="button-group-home">
          <Link to="/ja" className="button">HOME</Link>
        </div>
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </>
  );
};

export default Login;
