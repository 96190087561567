import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Tones = () => {
  useEffect(() => {
    document.title = 'リスニング';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
    <html>
    <body>
    <Header />
    <Banner className="listening-page"/>

    <div className="center-thai">
      <h1 className="title-each-page">タイ語のリスニング</h1>
      <div className="button-group">
      <Link to="/ja/greeting" className="button">あいさつ</Link>
      <Link to="/ja/greeting_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/shopping" className="button">ショッピング</Link>
      <Link to="/ja/shopping_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/trip" className="button">旅行</Link>
      <Link to="/ja/trip_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/restaurant" className="button">レストラン</Link>
      <Link to="/ja/restaurant_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/conversationwithfriends" className="button">友人との会話</Link>
      <Link to="/ja/conversationwithfriends_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/school" className="button">学校</Link>
      <Link to="/ja/school_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/drinkingparty" className="button">飲み会</Link>
      <Link to="/ja/drinkingparty_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/drive" className="button">ドライブ</Link>
      <Link to="/ja/drive_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/loveaffair" className="button">恋愛</Link>
      <Link to="/ja/loveaffair_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/beach" className="button">ビーチ</Link>
      <Link to="/ja/beach_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/reading_l" className="button">読書</Link>
      <Link to="/ja/reading_l_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/meal" className="button">食事</Link>
      <Link to="/ja/meal_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/music" className="button">音楽</Link>
      <Link to="/ja/music_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/art" className="button">美術、芸術</Link>
      <Link to="/ja/art_quiz" className="button">確認クイズ</Link>
      </div>

      <div className="button-group">
      <Link to="/ja/hospital" className="button">病院</Link>
      <Link to="/ja/hospital_quiz" className="button">確認クイズ</Link>
      </div>

      <br></br>


      <div className="button-group-home">
      <Link to="/ja" className="button">HOME</Link>
      </div>

    </div>
    {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </body>
    </html>
    </>
  );
};

export default Tones;