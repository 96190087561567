import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Tones = () => {
  useEffect(() => {
    document.title = 'Listening';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
    <html>
    <body>
    <Header />
    <Banner className="listening-page"/>

    <div className="center-thai">
      <h1 className="title-each-page">Thai Listening</h1>
      <div className="button-group">
      <Link to="/greeting" className="button">Greeting</Link>
      <Link to="/greeting_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/shopping" className="button">Shopping</Link>
      <Link to="/shopping_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/trip" className="button">Trip</Link>
      <Link to="/trip_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/restaurant" className="button">Restaurant</Link>
      <Link to="/restaurant_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/conversationwithfriends" className="button">Talk with Friends</Link>
      <Link to="/conversationwithfriends_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/school" className="button">School</Link>
      <Link to="/school_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/drinkingparty" className="button">Drinking Party</Link>
      <Link to="/drinkingparty_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/drive" className="button">Drive</Link>
      <Link to="/drive_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/loveaffair" className="button">Love Affair</Link>
      <Link to="/loveaffair_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/beach" className="button">Beach</Link>
      <Link to="/beach_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/reading_l" className="button">Reading Book</Link>
      <Link to="/reading_l_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/meal" className="button">Meal</Link>
      <Link to="/meal_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/music" className="button">Music</Link>
      <Link to="/music_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/art" className="button">Art</Link>
      <Link to="/art_quiz" className="button">Confirmation Quiz</Link>
      </div>

      <div className="button-group">
      <Link to="/hospital" className="button">Hospital</Link>
      <Link to="/hospital_quiz" className="button">Confirmation Quiz</Link>
      </div>
      <br></br>
      <div className="button-group-home">
      <Link to="/" className="button">HOME</Link>
      </div>

    </div>
    {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </body>
    </html>
    </>
  );
};

export default Tones;