import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Words = () => {
  useEffect(() => {
    document.title = 'Thai Words';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <html>
      <body>
      <Header />
      <Banner className="words-page"/>
  
      <div className="center-thai">
        <h1 className="title-each-page">Thai Words</h1>


        <div className="button-group">
        {/* 動詞 verb */} 
        <Link to="/verb" className="button">verb</Link>
         {/*  形容詞 adjective */} 
        <Link to="/adjective" className="button">adjective</Link>
        </div>

        <div className="button-group">
        {/* 動詞 verb */} 
        <Link to="/Auxiliary_Verb" className="button">auxiliary verb</Link>
         {/*  形容詞 adjective */} 
        <Link to="/Question" className="button">question</Link>
        </div>

        <div className="button-group">
        {/* 副詞 adverb*/} 
        <Link to="/Adverb" className="button">adverb</Link>
         {/*  前置詞 preposition */} 
        <Link to="/Preposition" className="button">preposition</Link>
        </div>

        <div className="button-group">
        {/* 副詞 adverb*/} 
        <Link to="/conjunction" className="button">conjunction</Link>
         {/*  前置詞 preposition */} 
        <Link to="/similar_verb" className="button">sililar verb</Link>
        </div>

        <div className="button-group">
        {/*  代名詞 pronoun */} 
        <Link to="/pronoun" className="button">pronoun</Link>
         {/* 時に関する単語 time */} 
         <Link to="/timeHome" className="button">time</Link>
        </div>


        <div className="button-group">
        {/*  数に関する単語 numbers */} 
        <Link to="/numbers" className="button">numbers</Link>  
        {/* 身体に関する単語 time */} 
        <Link to="/body" className="button">body</Link>
        </div>


        <div className="button-group">
        {/* 食事に関する言葉 everyday-life */} 
        <Link to="/meals" className="button">meals</Link>
        {/* 日常生活に関する言葉 everyday-life */} 
        <Link to="/everyday_life" className="button">everyday life</Link>


        </div>
        <br></br>

        <div className="button-group-home">
        <Link to="/" className="button">HOME</Link>
        </div>
  
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

      </body>
      </html>
    </>
    );
  };

export default Words;