import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';

const Index = () => {
  useEffect(() => {
    document.title = 'Index';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);
  const [jsonData, setJsonData] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // JSONデータを取得する関数
  useEffect(() => {
    axios.get('/json/index.json')
      .then(response => {
        setJsonData(response.data);
      })
      .catch(error => {
        console.error('データの取得に失敗しました:', error);
      });
  }, []);

  const navigate = useNavigate();

  const handleLinkClick = (item) => {
    navigate(`/detail/${item.id}`);
  }

  return (
    <>
    <Header />
    <Banner className="dictionary-page" />
    <div className='sententce-container'>


    <div className='sententce-container-index-page'>

      <div className="table-container" style={{ overflow: 'auto' }}>
      <h2 class="home-heading-index">9AG Index of Thai Words</h2>

        <table className="index-table-class"> {/* スタイルを適用するクラスを指定 */}
          <thead>
            <tr>
              <th>ID</th>
              <th>Category</th>
              <th>Word</th>
              <th>Reading</th>
              <th>Means</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            {jsonData.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.category}</td>
                <td>{item.Word}</td>
                <td>{item.Reading}</td>
                <td>{item.means}</td>
                <td><Link to={`/detail/${item.id}`} 
                state={{ 
                  category: item.category,
                  word: item.Word,
                  reading: item.Reading,
                  means: item.means,
                  audio: item.audio                  
                }} onClick={() => handleLinkClick(item)}>
                Link
                </Link></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
<br></br>
    <div className="center-thai">
      <div className="button-group-home">
        <Link to="/" className="button">HOME</Link>
      </div>
    </div>
    {shouldHideFooter ? null : <Footer className="custom-footer" />}
  </>
  );
};
export default Index;
