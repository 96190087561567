import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './App.css'; // スタイルシートをインポート
// import { appCssFile } from './App_CssFile;
import Header from './header';
import Banner from './banner';
import Footer from './footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Home = () => {
  useEffect(() => {
    document.title = 'Nine Arrows Geeks';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
  <>
  {/* <HelmetProvider>
    <Helmet>
      <meta name="description" content="タイ文字に関する説明文" />
      <link rel="apple-touch-icon" href="/ninearrowsgeeks.jpg" />
    </Helmet>
  </HelmetProvider> */}
    <html>
    <body>
      <Header />
      <Banner className="home-page" />
      <h2 class="home-heading">9AG Online Study Categories</h2>
      <div className="center-content">
        
        {/* コンテンツ */}
        <div className="l-wrapper_06">
          <div className="card_06">
            <img className="card-img_06" src="/images/thai-character3.jpg" alt="thai-character" />
            <div className="card-content_06">
              <p className="card-title_06">Thai character</p>
              <p className="card-text_06">The Thai alphabet consists of 42 consonants and vowels. If you can understand Thai letters, you will have a better resolution and enjoy Thai more!</p>
            </div>
            <div className="card-link_06">
              <Link to="/Thai_Character">Learn</Link><br />
            </div>
          </div>
        </div>

        <div className="l-wrapper_06">
          <div className="card_06">
            <img className="card-img_06" src="/images/words.jpg" alt="words" />
            <div className="card-content_06">
              <p className="card-title_06">Words</p>
              <p className="card-text_06">Learning words of thai means improving your Thai language skills. Learn as many words as you can and enjoy and elevate your Thailand life!</p>                                  
            </div>
            <div className="card-link_06">
              <Link to="/words">Learn</Link><br />
            </div>
          </div>
        </div>

        <div className="l-wrapper_06">
          <div className="card_06">
            <img className="card-img_06" src="/images/tones.jpg" alt="tones" />
            <div className="card-content_06">
              <p className="card-title_06">Tones</p>
              <p className="card-text_06">The most important factor in learning and using Thai is the tone of voice. Once you have mastered the tones, you may already be fluent in Thai!</p>
            </div>
            <div className="card-link_06">
              <Link to="/Tones">Learn</Link><br />
            </div>
          </div>
        </div>

        <div className="l-wrapper_06">
          <div className="card_06">
            <img className="card-img_06" src="/images/grammar.jpg" alt="grammar" />
            <div className="card-content_06">
              <p className="card-title_06">Grammar</p>
              <p className="card-text_06">The Thai grammar rules are characterized by familiar rules such as S+V+C and easy to understand words that do not change due to self-control or other reasons!</p>
            </div>
            <div className="card-link_06">
              <Link to="/Grammar">Learn</Link><br />
            </div>
          </div>
        </div>
        <div className="l-wrapper_06">
          <div className="card_06">
            <img className="card-img_06" src="/images/listening.jpg" alt="grammar" />
            <div className="card-content_06">
              <p className="card-title_06">Listening</p>
              <p className="card-text_06">Listening is essential for conversation with Thai people, and once you have some understanding of tone of voice, try listening to improve your listening skills!</p>
            </div>
            <div className="card-link_06">
              <Link to="/listening">Learn</Link><br />
            </div>
          </div>
        </div>
        <div className="l-wrapper_06">
          <div className="card_06">
            <img className="card-img_06" src="/images/reading.jpg" alt="grammar" />
            <div className="card-content_06">
              <p className="card-title_06">Reading</p>
              <p className="card-text_06">The way to become an advanced Thai speaker is through Thai script reading! If you can read Thai character and letters, you will definitely be different from others!</p>
            </div>
            <div className="card-link_06">
              <Link to="/reading">Learn</Link><br />
            </div>
          </div>
        </div>
    </div>
    
  <h2 class="home-heading">Powering an enabled Thai Life with 9AG</h2>
        
  <div className="center-content">
        <div class="card-container2">
        <div class="card2 card-skin">
        <div class="card2__imgframe_5"></div>
        <div class="card2__textbox">
        <Link to="index" className='link-text'>  
          <div class="card2__titletext">
            Index
          </div>
        </Link>
          <div class="card2__overviewtext">
          The Index of 9AG is a handy reverse lookup table to support learning.it collects and organizes words and phrases in different words effectivel.
          </div>
        </div>
        </div>

        <div class="card2 card-skin">
        <div class="card2__imgframe_1"></div>
        <div class="card2__textbox">
        <Link to="ninearrowsgeeks" className='link-text'>
          <div class="card2__titletext">
            About 9AG
          </div>
        </Link>
        <div class="card2__overviewtext">
          9AG(Nine Arrows Geeks) is a premium online learning platform specializing in the Thai language. Symbolized by the auspicious number "9" in Thai culture
          </div>
        </div>
        </div>



        <div class="card2 card-skin">
        <div class="card2__imgframe_youtube"></div>
        <div class="card2__textbox">
        <Link to="https://www.youtube.com/channel/UCgFMXYKvTSnXVVqQ_EnIW6A" className='link-text'>  
          <div class="card2__titletext">
            Youtube 
          </div>
        </Link>
          <div class="card2__overviewtext">
          9AG also provides content on YouTube to help you learn,including a YouTube channel with video lessons focusing on common expressions commonly used in thai language.
          </div>
        </div>
        </div>

        <div class="card2 card-skin">
        <div class="card2__imgframe_mobile"></div>
        <div class="card2__textbox">
        <Link to="mobile" className='link-text'> 
          <div class="card2__titletext">
            Mobile App
          </div>
          </Link>
          <div class="card2__overviewtext">
          9AG will provides study content on its mobile apps to aid learning. Learning progress on the app and website are synchronized for a seamless learning experience
          </div>
        </div>
        </div>

        </div>

        
  </div>

  <div className="center-content">
        <div class="card-container2">
        <div class="card2 card-skin">
        <div class="card2__imgframe_2"></div>
        <div class="card2__textbox">
        <Link to="privacypolicy" className='link-text'>  
          <div class="card2__titletext">
            Our Privacy Policy
          </div>
        </Link>
          <div class="card2__overviewtext">
          9AG takes your personal information seriously and does its best to protect it by IT security. The information you entrust to us is kept in strict security
          </div>
        </div>
        </div>

        <div class="card2 card-skin">
        <div class="card2__imgframe_4"></div>
        <div class="card2__textbox">
        <Link to="login" className='link-text'>  
          <div class="card2__titletext">
            User Login
          </div>
        </Link>
          <div class="card2__overviewtext">
          User login is the process of controlling access to a 9AG website and our application,Log in to study Thai efficiently, including score management.
          </div>
        </div>
        </div>

        <div class="card2 card-skin">
        <div class="card2__imgframe_register"></div>
        <div class="card2__textbox">
        <Link to="register" className='link-text'>  
          <div class="card2__titletext">
            Registartion
          </div>
        </Link>
          <div class="card2__overviewtext">
          If you register as a user with 9AG, you can save what you have studied on 9AG and study Thai efficiently. An AI-based advice function will also be implemented in the future.
          </div>
        </div>
        </div>

        <div class="card2 card-skin">
        <div class="card2__imgframe_3"></div>
        <div class="card2__textbox">
        <Link to="contactform" className='link-text'> 
          <div class="card2__titletext">
            Contact us
          </div>
          </Link>
          <div class="card2__overviewtext">
          To enhance your learning experience with Nine Arrows Geeks, we always listen to our users. If you have any questions,please feel free to contact us
          </div>
        </div>
        </div>





        </div>

        
  </div>
  

      {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </body>
    </html>
  </>
  );
};

export default Home;
