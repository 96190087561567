import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  CardContainer,
  Card,
  CardImage,
  CardTitle,
  CardWords,
  CardWord,
  CardDescription,
  ButtonContainer,
  Button
} from "../css/style"; // styles.jsのパスを適切に指定
import Header from '../header';
import Footer from '../footer';
import '../App.css';

const Diphthong = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/json/thai_character/diphthong.json");
        const jsonData = await response.json();
        console.log(jsonData)

        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    
    fetchData(); // fetchData関数を呼び出す
    document.title = 'Thai double vowels';
  }, []);

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };

  const currentCard = data[currentIndex];

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
//currentCard が設定される前に <Card> コンポーネントがレンダリングされる可能性があるため、
//currentCard が存在する場合にのみ <Card> コンポーネントをレンダリングする
<div>
<body>
<Header />
<div className="center-content2">
{currentCard && (
  <CardContainer>
    <Card>
      <CardTitle>{currentCard.character}</CardTitle>
      <CardDescription>{currentCard.read}</CardDescription>
      <CardDescription>{currentCard.meaning}</CardDescription>
    </Card>
  </CardContainer>
)}
      <ButtonContainer>
        <Button onClick={handlePrevClick} className="button">Prev</Button>
        <Button onClick={handleNextClick} className="button">Next</Button>
      </ButtonContainer>
      <br></br>
      <ButtonContainer>
          <Link to="/thai_character" className="button">Return</Link> {/* / に戻るリンク */}
      </ButtonContainer>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

      </div>
      </body>
    </div>
  );
};

export default Diphthong;
