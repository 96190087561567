import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import '../App.css';

const ThaiCharacter = () => {
  useEffect(() => {
    document.title = 'タイ文字';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
      <html>
        <body>
          <Header />
          <Banner className="thai-character-page" />

          <div className="center-thai">
            <h1 className="title-each-page">タイ文字</h1>
            <div className="button-group">
              <Link to="/ja/consonant" className="button">子音</Link>
              <Link to="/ja/consonant_quiz" className="button">子音 確認クイズ</Link>
            </div>
            <div className="button-group">
              <Link to="/ja/vowel" className="button">母音</Link>
              <Link to="/ja/vowel_quiz" className="button">母音 確認クイズ</Link>
            </div>
            <div className="button-group">
              <Link to="/ja/diphthong" className="button">二重母音</Link>
              <Link to="/ja/diphthong_quiz" className="button">二重母音 確認クイズ</Link>
            </div>
            <br></br>

            <div className="button-group-home">
              <Link to="/ja/" className="button">ホーム</Link>
            </div>

          </div>
          {shouldHideFooter ? null : <Footer className="custom-footer" />}
        </body>
      </html>
  );
};

export default ThaiCharacter;
