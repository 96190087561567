import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom'; // react-router-domからLinkをインポート

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = window.innerWidth <= 500;

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 500;
      if (mobile !== isMobile) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  // ログイン状態をローカルストレージから取得
  const isLoggedIn = !!localStorage.getItem('accessToken');

  // ダミーのログアウト関数
  const handleLogout = () => {
    // ローカルストレージからアクセストークンを削除
    localStorage.removeItem('accessToken');
    // ログアウト後にログイン状態を更新
    window.location.href = '/'; // ログアウト後にホームページにリダイレクト
  };

  return (
    <header>
      <Link className="link-line-delete" to="/">
        <img className="header-image" src="/images/9ag-logo-noname.jpg" alt="NINE ARROWS GEEKS" />
      </Link>
      <h1 className="title">
        <Link className="link-line-delete" to="/ja">
          <div className="header-item">Nine Arrows Geeks</div>
        </Link>
      </h1>

      {isMobile ? (
        <div className="bm-burger-button" onClick={() => setMenuOpen(!menuOpen)}>
        </div>
      ) : null}

      {isMobile ? (
        <Menu right width={'35%'} isOpen={menuOpen} onStateChange={({ isOpen }) => setMenuOpen(isOpen)} disableAutoFocus>
          <ul>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja" onClick={closeMenu}>
                <div className="header-item-hamburger">ホーム</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/Thai_Character" onClick={closeMenu}>
                <div className="header-item-hamburger">タイ文字</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/Words" onClick={closeMenu}>
                <div className="header-item-hamburger">タイ単語</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/Tones" onClick={closeMenu}>
                <div className="header-item-hamburger">タイ声調</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/Grammar" onClick={closeMenu}>
                <div className="header-item-hamburger">タイ文法</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/listening" onClick={closeMenu}>
                <div className="header-item-hamburger">リスニング</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/index" onClick={closeMenu}>
                <div className="header-item-hamburger">インデックス</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/reading" onClick={closeMenu}>
                <div className="header-item-hamburger"></div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/ninearrowsgeeks" onClick={closeMenu}>
                <div className="header-item-hamburger">9AG</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/privacypolicy" onClick={closeMenu}>
                <div className="header-item-hamburger">情報保護方針</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/contactform" onClick={closeMenu}>
                <div className="header-item-hamburger">問い合わせ</div>
              </Link>
            </li>
            {isLoggedIn ? (
              <>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/ja/dashboard" onClick={closeMenu}>
                    <div className="header-item-hamburger">ダッシュボード</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <div className="close" onClick={closeMenu}>
                    <span className="header-item-hamburger" onClick={handleLogout}>ログアウト</span>
                  </div>
                </li>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/" onClick={closeMenu}>
                    <div className="header-item-hamburger">英語版</div>
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/ja/login" onClick={closeMenu}>
                    <div className="header-item-hamburger">ログイン</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/ja/register" onClick={closeMenu}>
                    <div className="header-item-hamburger">ユーザ登録</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/" onClick={closeMenu}>
                    <div className="header-item-hamburger">英語版</div>
                  </Link>
                </li>
              </>
            )}
            <li className="menu-item-header">
              <div className="close" onClick={closeMenu}>
                <span className="header-item-hamburger">閉じる</span>
              </div>
            </li>
          </ul>
        </Menu>
      ) : (
        <nav className="normal-menu">
          <ul>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/Thai_Character">
                <div className="header-item">タイ文字</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/Words">
                <div className="header-item">単語</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/Tones">
                <div className="header-item">声調</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/Grammar">
                <div className="header-item">文法</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/listening">
                <div className="header-item">リスニング</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/reading">
                <div className="header-item">リーディング</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/index">
                <div className="header-item">インデックス</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/ninearrowsgeeks">
                <div className="header-item">9AG</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/privacypolicy">
                <div className="header-item">情報保護方針</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ja/contactform">
                <div className="header-item">問い合わせ</div>
              </Link>
            </li>
            <li className="menu-item-header">
                  <Link className="link-line-delete" to="/">
                    <div className="header-item">英語版</div>
                  </Link>
                </li>
            {isLoggedIn ? (
              <>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/ja/dashboard">
                    <div className="header-item">ダッシュボード</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <div className="header-item" onClick={handleLogout}>ログアウト</div>
                </li>
              </>
            ) : (
              <>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/ja/login">
                    <div className="header-item">ログイン</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/ja/register">
                    <div className="header-item">ユーザ登録</div>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
