import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Words = () => {
  useEffect(() => {
    document.title = 'Thai words of meals';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <html>
      <body>
      <Header />
      <Banner className="words-page"/>
  
      <div className="center-thai">
        <h1 className="title-each-page">Words of Meals</h1>

        <div className="button-group">
        <Link to="/cooking" className="button">cooking</Link>  
        <Link to="/cooking_quiz" className="button">Confirmation Quiz</Link>
        </div>

        <div className="button-group">
        <Link to="/daily_meal" className="button">daily meal</Link>  
        <Link to="/daily_meal_quiz" className="button">Confirmation Quiz</Link>
        </div>

        <div className="button-group">
        <Link to="/drink" className="button">drink</Link>  
        <Link to="/drink_quiz" className="button">Confirmation Quiz</Link>
        </div>

        <div className="button-group">
        <Link to="/fruits_seafood" className="button">fruits,seafood</Link>  
        <Link to="/fruits_seafood_quiz" className="button">Confirmation Quiz</Link>
        </div>

        <div className="button-group">
        <Link to="/kitchen" className="button">kitchen</Link>  
        <Link to="/kitchen_quiz" className="button">Confirmation Quiz</Link>
        </div>

        <div className="button-group">
        <Link to="/food_material" className="button">food material</Link>  
        <Link to="/food_material_quiz" className="button">Confirmation Quiz</Link>
        </div>

        <div className="button-group">
        <Link to="/seasoning" className="button">seasoning</Link>  
        <Link to="/seasoning_quiz" className="button">Confirmation Quiz</Link>
        </div>

        <div className="button-group">
        <Link to="/restraunt" className="button">restraunt</Link>  
        <Link to="/restraunt_quiz" className="button">Confirmation Quiz</Link>
        </div>

        <div className="button-group-home">
        <Link to="/words" className="button">return</Link>
        </div>
  
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

      </body>
      </html>
    </>
    );
  };

export default Words;