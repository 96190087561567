import React, { useState, useEffect } from "react";
import { Quiz_Listening } from './quiz_listening.js'; // Quiz コンポーネントをインポート
import Header from '../header_JP';
import Footer from '../footer';
import '../App.css';

const Tones_Quiz = () => {
    // Quiz コンポーネントを使用
    useEffect(() => {
      document.title = 'タイ語 声調クイズ';
    }, []);

    const [shouldHideFooter, setShouldHideFooter] = useState(false);

    useEffect(() => {
      // スクロールイベントリスナーを追加
      const handleScroll = () => {
        // スクロール位置を取得
        const scrollY = window.scrollY;
  
        // スクロール位置が1000pxを超えた場合にフッターを非表示にする
        if (scrollY >= 30) {
          setShouldHideFooter(true);
        } else {
          setShouldHideFooter(false);
        }
      };
  
      // スクロールイベントリスナーを追加
      window.addEventListener('scroll', handleScroll);
  
      // コンポーネントがアンマウントされたときにイベントリスナーを削除
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  // Quiz コンポーネントを使用
  return (
    <div>
      <body>
      <Header />
      <div className="center-content3">
      <Quiz_Listening quizDataPath='/json/tones/tones_quiz_JP.json' quizForeign={59}/>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
      </div>
      </body>
    </div>
  );
};

export default Tones_Quiz;