import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  CardContainer,
  Card,
  CardImage,
  CardTitle,
  CardWords,
  CardWord,
  CardDescription,
  ButtonContainer,
  Button
} from "../css/style"; // styles.jsのパスを適切に指定
import Header from '../header_JP';
import Footer from '../footer';
import '../App.css';

export const Basic_Listening = ({ dataPath }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [audioPath, setAudioPath] = useState("");
  const [audio, setAudio] = useState(null); // 音声オブジェクトを管理
  const [audioContext, setAudioContext] = useState(null);

  const quizTextStyle = {
    fontSize: '100px',
    margin: '5px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  // モバイルデバイス用のフォントサイズ
  const mobileQuizTextStyle = {
    fontSize: '70px',
    margin: '5px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(dataPath);
        const jsonData = await response.json();
        setData(jsonData);
        setAudioPath(jsonData[0].audioPath); // 最初のデータの音声パスを設定
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
        // オーディオコンテキストを初期化
        const context = new (window.AudioContext || window.webkitAudioContext)();
        setAudioContext(context);
    
        // コンポーネントのアンマウント時にオーディオコンテキストを解放
        return () => {
          if (context.state === "running") {
            context.close();
          }
        };
  }, [dataPath]);

  const playAudio = async (audioPath) => {
    if (audioContext) {
      try {
        await audioContext.resume(); // オーディオコンテキストを再開
  
        const response = await fetch(audioPath);
        const data = await response.arrayBuffer();
        const buffer = await audioContext.decodeAudioData(data);
  
        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);
        source.start();
      } catch (error) {
        console.error("Error playing audio: ", error);
      }
    }
  };

  // const playAudio = (audioPath) => {
  //   // 現在の音声を停止
  //   if (audio) {
  //     audio.pause();
  //   }

  //   const newAudio = new Audio(audioPath);
  //   newAudio.play();
  //   setAudio(newAudio);
  // };

  const handleNextClick = () => {
    const nextIndex = (currentIndex + 1) % data.length;
    setCurrentIndex(nextIndex);
    playAudio(data[nextIndex].audioPath);
    // setAudioPath(data[nextIndex].audioPath);
  };

  const handlePrevClick = () => {
    const prevIndex = currentIndex === 0 ? data.length - 1 : currentIndex - 1;
    setCurrentIndex(prevIndex);
    playAudio(data[prevIndex].audioPath);
    // setAudioPath(data[prevIndex].audioPath);
  };

  const currentCard = data[currentIndex];

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  };

  return (
    <div style={containerStyle}>
      {currentCard && (
        <div>
          {/* モバイルデバイスでフォントサイズを切り替え */}
          <h2 style={window.innerWidth <= 768 ? mobileQuizTextStyle : quizTextStyle}>{currentCard.character}</h2>
          <p>読み: {currentCard.read.join(", ")}</p>
          <p>意味: {currentCard.meaning}</p>
          <button onClick={() => playAudio(currentCard.audioPath)}>音声を再生</button>
          {/* <button onClick={() => playAudio(audioPath)}>Audio</button> */}
        </div>
      )}
      <br></br>
      <ButtonContainer>
        <Button onClick={handlePrevClick}
                className="button"
                style={{ width: "150px" }}
        >前へ</Button>
        <Button onClick={handleNextClick}
                className="button"
                style={{ width: "150px" }}
        >次へ</Button>
      </ButtonContainer>

      <div>
        <br></br>
        <ButtonContainer>
          <Link to="/ja/listening" className="button">戻る</Link> {/* / に戻るリンク */}
      </ButtonContainer>
      </div>
    </div>
  );
};

export default Basic_Listening;
