import React, {useState,useEffect} from 'react'
import './App.css';
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <h1>NotFound</h1>
    </>
  );
};

export default NotFound;