import styled from "styled-components";

export const CardContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
max-width:2000px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width:500px;
`;

export const CardImage = styled.img`
  width: 120px;
  height: 120px;
  max-width: 200px;
  max-height: 200px;
`;

export const CardTitle = styled.h3`
  margin-top: -35px;
  margin-bottom: 1px;
  font-size: 150px;
`;

export const CardTitleTones = styled.h3`
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 40px;
`;

export const CardTitleGrammar = styled.h3`
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 30px;
`;

export const CardWordGrammar = styled.div`
  font-size: 20px;
  margin-right: 5px;
`;

export const CardWords = styled.div`
  margin-bottom: 5px;
  max-width: auto;
  font-size: 40px;
`;

export const CardWord = styled.span`
  margin-right: 5px;
  font-weight: bold;
`;

export const CardDescription = styled.div`
  font-size: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const Button = styled.button`
  margin-right: 10px;
  margin-left: 10px;
`;

export const AnswerOptions = styled.button`
  margin-right: 10px;
  margin-left: 10px;
`;

