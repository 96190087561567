import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';
import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';

const Dashboard = () => {
  useEffect(() => {
    document.title = 'ダッシュボード';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);
  const [userData, setUserData] = useState(null);
  const [scoreData, setScoreData] = useState(null); // 新しいステート変数

  const [modalData, setModalData] = useState(null); // モーダル用のデータ
  const [isModalOpen, setIsModalOpen] = useState(false); // モーダルの表示/非表示ステート

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // ローカルストレージからJWTアクセストークンを取得
    const accessToken = localStorage.getItem('accessToken');

    // アクセストークンが存在する場合、DjangoのAPIからユーザーデータを取得
    if (accessToken) {
      //Local
      // axios.get('http://localhost:8000/api/auth/users/me/', {
      //Production
      axios.get('https://bitmerica.net/api/auth/users/me/', {
        headers: {
          'Authorization': `JWT ${accessToken}`,
        },
      })
      .then(response1 => {
        setUserData(response1.data);
      })
      .catch(error => {
        console.error('ユーザーデータの取得に失敗しました', error);
      });
      // スコア情報取得
      //Production
      axios.get('https://bitmerica.net/api/scores/list/', {
      //Local
      // axios.get('http://localhost:8000/api/scores/list/', {
        headers: {
          'Authorization': `JWT ${accessToken}`,
        },
      })
      .then(response2 => {
        setScoreData(response2.data);
        console.log(scoreData);
      })
      .catch(error => {
        console.error('Failed to get score data', error);
      });
    }
  }, []);

    // モーダルを開く関数
    const openModal = (data) => {
      setModalData(data);
      setIsModalOpen(true);
    };
  
    // モーダルを閉じる関数
    const closeModal = () => {
      setIsModalOpen(false);
    };

  const resetEmail = () => {
    if (userData && userData.email) {
      const accessToken = localStorage.getItem('accessToken');
      
      //Local
      // axios.post('http://localhost:8000/api/auth/users/reset_email/', {
      //Production
      axios.post('https://bitmerica.net/api/auth/users/reset_email/', {
        email: userData.email,
      }, {
        headers: {
          'Authorization': `JWT ${accessToken}`,
        },
      })
      .then(response => {
        // パスワードリセットリクエストが成功した場合の処理
        console.log('mailアドレス変更リクエストが成功しました。');
        alert('mailアドレス変更リクエストが成功しました。mailアドレス変更用のメールを送信しましたのでご確認ください');
        window.location.href = '/'; // ホームページにリダイレクト
      })
      .catch(error => {
        // エラーハンドリング
        console.error('mailアドレス変更リクエストに失敗しました', error);
        alert('mailアドレス変更リクエストに失敗しました。エラー: ' + error.message);
      });
    } else {
      alert('ユーザーデータが不足しています。');
    }
  };

  const modalStyle1 = {
    content: {
      width: '300px',
      height: '200px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  };

    // テーブルにボーダースタイルを定義
    const tableStyle = {
      borderCollapse: 'collapse',
      width: '100%',
    };

    const cellStyle = {
      border: '1px solid #dddddd',
      textAlign: 'left',
      padding: '8px',
    };

  return (
    <>
      <Header />
      <Banner className="user-page" />

      <Modal
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        style={modalStyle1}
      >
        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <button onClick={closeModal}>Close</button>
              <h2><b>Quiz Details</b></h2>
              {modalData && (
                <table style={tableStyle}>
                  <tbody>
                    {Object.entries(modalData).map(([key, value]) => (
                      key !== 'quiz_foreign' && key !== 'score' && (
                        <tr key={key}>
                          <td style={cellStyle}>{key}:</td>
                          <td style={cellStyle}>{value.toString()}</td>
                        </tr>
                      )
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}
      </Modal>

      <div className="input-container">
        {userData ? (
          <div>
            <h2><b>ユーザー情報</b></h2>
            <p><b>ユーザ名:</b> {userData.name} <Link to="/ja/userChange">変更</Link></p>
            <p><b>Email:</b> {userData.email} <button onClick={resetEmail}>変更</button></p>
            <p><b>パスワード:</b>セキュリティ上表示できません <Link to="/ja/passwordChange">変更</Link></p>  
          </div>
        ) : (
          <p>データをロード中です...</p>
        )}

        {scoreData ? (
          <div>
            <h2><b>Quiz Result</b></h2>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={cellStyle}>Quiz Foreign</th>
                  <th style={cellStyle}>Score</th>
                  <th style={cellStyle}>Details</th>
                </tr>
              </thead>
              <tbody>
                {scoreData.map((item, index) => (
                  <tr key={index}>
                    <td style={cellStyle}>{item.quiz_foreign}</td>
                    <td style={cellStyle}>{item.score}</td>
                    <td style={cellStyle}>
                      <button onClick={() => openModal(item)}>Details</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>Data Loading</p>
        )}
      </div>
      <br></br>
      <div className="center-thai">
        <div className="button-group-home">
          <Link to="/" className="button">HOME</Link>
        </div>
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

    </>

  );
};

export default Dashboard;