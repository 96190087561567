import React, { useState, useEffect } from "react";
import { ThaiCharacterQuiz } from './quiz.js'; // Quiz コンポーネントをインポート
import Header from '../header_JP';
import Footer from '../footer';
import '../App.css';

const Diphthong_Quiz = () => {
  // Quiz コンポーネントを使用
  useEffect(() => {
    document.title = 'タイ語 2重母音クイズ';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <body>
      <Header />
      <div className="center-content3">
      <ThaiCharacterQuiz quizDataPath='/json/thai_character/diphthong_quiz.json' quizForeign={3}/>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
      </div>
      </body>
    </div>
  );
};

export default Diphthong_Quiz;