import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

// オプションをランダムにシャッフルする関数
function shuffleArray(array) {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

export const GrammarQuiz = ({ quizDataPath, quizForeign  }) => {
  const [quizData, setQuizData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userResponses, setUserResponses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCorrectPopup, setIsCorrectPopup] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [shuffledOptions, setShuffledOptions] = useState([]); // シャッフルされたオプションを保持

  const navigate = useNavigate();

  const modalStyle1 = {
    content: {
      width: '300px',
      height: '200px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)', // 画面の中央に配置
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
  
  
  const modalStyle2 = {
    content: {
      width: '300px',
      height: 'auto',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)', // 画面の中央に配置
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  const buttonContainerStyle = {
    // ... ボタンコンテナのスタイル設定
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(quizDataPath);
        if (!response.ok) {
          throw new Error(`Fetch failed with status: ${response.status}`);
        }
        const data = await response.json();

        setQuizData(data);

        // 最初の質問のオプションをシャッフルしてセット
        const shuffledOptions = shuffleArray(data[currentQuestion].options);
        setShuffledOptions(shuffledOptions);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching quiz data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [quizDataPath, currentQuestion]);

  const handleOptionClick = (selectedOption) => {
    setSelectedOptions((prevSelectedOptions) => [
      ...prevSelectedOptions,
      selectedOption,
    ]);
  };

  const handleCheckAnswer = () => {
    const currentQuiz = quizData[currentQuestion];
    const isCorrect =
      JSON.stringify(selectedOptions) === JSON.stringify(currentQuiz.answer);

    setUserResponses([
      ...userResponses,
      {
        id: quizData[currentQuestion].id, // シャッフル前のデータのIDを保持
        question: currentQuiz.question,
        answer: selectedOptions,
        isCorrect,
      },
    ]);

    console.log(quizData[currentQuestion].id)


    if (isCorrect) {
      setScore(score + 1);
    }

    setCorrectAnswer(currentQuiz.answer.join(', '));
    setIsCorrectPopup(isCorrect);
    setIsModalOpen(true);
  };

  const handleNextQuestion = () => {
    setIsModalOpen(false);

    if (currentQuestion === quizData.length - 1) {
      navigate('/score', { state: { score, userResponses } });
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOptions([]);
      setCorrectAnswer('');
      const shuffledOptions = shuffleArray(quizData[currentQuestion + 1].options);
      setShuffledOptions(shuffledOptions);
    }
  };

  // スコアを送信する関数
  const sendScore = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      
      if (!accessToken) {
        // accessToken が存在しない場合はスコアを送信しない
        console.warn('No access token found. Score not sent.');
        return;
      }

     // ユーザーの回答の正誤情報をquizDataの昇順で取得
     const userResponsesIsCorrect = quizData.map((item, index) => {
      const sortedQuizData = [...quizData].sort((a, b) => a.id - b.id); // idを昇順にソートした新しい配列を作成
      const correspondingItem = sortedQuizData[index]; // ソート済み配列から現在のindexに対応するクイズデータを取得
    
      // 対応するクイズデータのisCorrectを取得
      const userResponse = userResponses.find((response) => response.id === correspondingItem.id);
    
      return {
        [`a_${String(index + 1).padStart(3, '0')}`]: userResponse ? userResponse.isCorrect : false,
      };
    });

      //Local
      // const response = await fetch('http://localhost:8000/api/scores/', {
      //Production
      const response = await fetch('https://bitmerica.net/api/scores/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${accessToken}`,
        },
        body: JSON.stringify({
          score: score,
          quiz_foreign: quizForeign,
          ...userResponsesIsCorrect.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          // 他の必要なデータをここに追加できます
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to send score with status: ${response.status}`);
      }

      // スコアが正常に送信された場合の処理をここに追加します

    } catch (error) {
      console.error('Error sending score:', error);
    }
  };

  // スコアページを閉じる処理

  const handleScorePageClose = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      sendScore(); // accessToken が存在する場合にのみスコアを送信
    }
    navigate('/ja/grammar');
  };

  const handleQuit = () => {
    navigate('/ja/grammar');
  };

  return (
    <div className='quiz-app'>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <div className='quiz-section'>
            <p>質問 {currentQuestion + 1} / {quizData.length}</p>
            <p>{quizData[currentQuestion].question}</p>
          </div>
          <div className='options-section'>
            <ul>
              {shuffledOptions.map((option, index) => (
                <li key={index}>
                  <button
                    onClick={() => handleOptionClick(option)}
                    className="button"
                    disabled={selectedOptions.includes(option)}
                  >
                    {option}
                  </button>
                </li>
              ))}
            </ul>
            <div>
              <h3>選択したオプション:</h3>
              <p>{selectedOptions.join(', ')}</p>
            </div>
            <button
              onClick={handleCheckAnswer}
              disabled={selectedOptions.length === 0}
              className="button"
            >
              回答を確認
            </button>
          </div>
          <br></br>
          <br></br>
          <div style={buttonContainerStyle}>
            <button onClick={handleQuit} className='button'>やめる</button>
          </div>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={handleNextQuestion}
            ariaHideApp={false}
            style={currentQuestion === quizData.length - 1 ? modalStyle2 : modalStyle1}
            contentLabel="モーダル"
          >
            <div className={`answer-feedback ${isCorrectPopup ? 'correct' : 'incorrect'}`}>
              {isCorrectPopup ? '正解です！' : '不正解です。'}
              <div>
                <h2>正解はこちら:</h2>
                <p>{correctAnswer}</p>
              </div>
              {currentQuestion === quizData.length - 1 && (
                <div>
                  <h2>スコア</h2>
                  <p>正解数: {score} / {quizData.length}</p>
                  <h2>回答一覧</h2>
                  <ul>
                    {userResponses.map((response, index) => (
                      <li key={index}>
                        <p>質問: {response.question}</p>
                        <p>回答: {response.answer.join(', ')}</p>
                        <p>
                          {response.isCorrect
                            ? '正解です！'
                            : '不正解です。'}
                        </p>
                      </li>
                    ))}
                  </ul>
                  <div style={buttonContainerStyle}>
                    <button onClick={handleScorePageClose} className="button">閉じる</button>
                  </div>
                </div>
              )}
              {currentQuestion !== quizData.length - 1 && (
                <div style={buttonContainerStyle}>
                  <button onClick={handleNextQuestion} className="button">次の質問へ</button>
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default GrammarQuiz;
