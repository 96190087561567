import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link,useLocation } from 'react-router-dom';
import {
  CardContainer,
  Card,
  CardImage,
  CardTitle,
  CardWords,
  CardWord,
  CardDescription,
  ButtonContainer,
  Button
} from "../css/style"; // styles.jsのパスを適切に指定
import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';

function Detail() {

  const [shouldHideFooter, setShouldHideFooter] = useState(false);
  const [data, setData] = useState([]);
  const [audio, setAudio] = useState(null); // 音声オブジェクトを管理
  const [audioContext, setAudioContext] = useState(null);
  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    document.title = `${state.word} ${state.means}`;
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    
  useEffect(() => {    
    // オーディオコンテキストを初期化
    const context = new (window.AudioContext || window.webkitAudioContext)();
    setAudioContext(context);

    // コンポーネントのアンマウント時にオーディオコンテキストを解放
    return () => {
      if (context.state === "running") {
        context.close();
      }
    };
  }, []);
  
  const playAudio = async (audioPath) => {
    if (audioContext) {
      try {
        await audioContext.resume(); // オーディオコンテキストを再開
  
        const response = await fetch(audioPath);
        const data = await response.arrayBuffer();
        const buffer = await audioContext.decodeAudioData(data);
  
        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);
        source.start();
      } catch (error) {
        console.error("Error playing audio: ", error);
      }
    }
  };

  // モバイルデバイス用のフォントサイズ
  const mobileQuizTextStyle = {
    fontSize: '70px',
    margin: '5px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const quizTextStyle = {
    fontSize: '100px',
    margin: '5px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  };
  return (
    <>

    <Header />
    <div className='center-content2'>
    <div style={containerStyle}>
    <h2 style={window.innerWidth <= 768 ? mobileQuizTextStyle : quizTextStyle}>{state.word}</h2>
      <button onClick={() => playAudio("/audio/words/"+state.audio+".m4a")}>Audio</button>
      <p>Reading: {state.reading}</p>
      <p>Meaning: {state.means}</p>
      <p>Category: {state.category}</p>
      <br></br>
    <div className="center-thai">
      <div className="button-group-home">
        <Link to="/index" className="button">Return</Link>
      </div>
    </div>
    {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </div>
    </div>
  </>
  );
}

export default Detail;
