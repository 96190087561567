import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import '../App.css';

import {
  CardContainer,
  Card,
  CardImage,
  CardTitleTones,
  CardWords,
  CardWord,
  CardDescription,
  ButtonContainer,
  Button
} from "../css/style";

export const ThaiCharacterQuiz = ({ quizDataPath, quizForeign }) => {
  //ステートの初期化
  const [quizData, setQuizData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userResponses, setUserResponses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCorrectPopup, setIsCorrectPopup] = useState(false);

  const navigate = useNavigate();

  const modalStyle1 = {
    content: {
      width: '300px',
      height: '200px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)', // 画面の中央に配置
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
  
  
  const modalStyle2 = {
    content: {
      width: '300px',
      height: 'auto',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)', // 画面の中央に配置
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
  

  const quizTextStyle = {
    fontSize: '100px',
    margin: '5px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };



  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
  };

  //クイズデータのロードとシャッフル
  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const response = await fetch(quizDataPath);
        if (!response.ok) {
          throw new Error(`Fetch failed with status: ${response.status}`);
        }
        const data = await response.json();
        
        const shuffledData = shuffleArray(data);
        setQuizData(shuffledData);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching quiz data:', error);
        setIsLoading(false);
      }
    };

    fetchQuizData();
  }, [quizDataPath]);

  //配列をシャッフルする関数
  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };
  // 現在のクイズの回答オプションをシャッフルする関数
  const shuffleAnswerOptions = () => {
    const currentQuiz = quizData[currentQuestion];
    const shuffledAnswerOptions = shuffleArray(currentQuiz.answerOptions);
    return shuffledAnswerOptions;
  };

  // ユーザーの回答を処理する関数
  const handleAnswerOptionClick = (selectedAnswer) => {
    const isCorrect = selectedAnswer === quizData[currentQuestion].correctAnswer;

    setUserResponses([
      ...userResponses,
      {
        id: quizData[currentQuestion].id, // シャッフル前のデータのIDを保持
        question: quizData[currentQuestion].questionText,
        selectedAnswer,
        isCorrect,
        correctAnswer: quizData[currentQuestion].correctAnswer,
      },
    ]);

   console.log(quizData[currentQuestion].id)

    if (isCorrect) {
      setScore(score + 1);
    }

    setIsCorrectPopup(isCorrect);
    setIsModalOpen(true);
  };
  // 次の質問に進む処理
  const handleNextQuestion = () => {
    setIsModalOpen(false);

    if (currentQuestion === quizData.length - 1) {
      setIsModalOpen(true);
    } else {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  // スコアを送信する関数
  const sendScore = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      
      if (!accessToken) {
        // accessToken が存在しない場合はスコアを送信しない
        console.warn('No access token found. Score not sent.');
        return;
      }

     // ユーザーの回答の正誤情報をquizDataの昇順で取得
     const userResponsesIsCorrect = quizData.map((item, index) => {
      const sortedQuizData = [...quizData].sort((a, b) => a.id - b.id); // idを昇順にソートした新しい配列を作成
      const correspondingItem = sortedQuizData[index]; // ソート済み配列から現在のindexに対応するクイズデータを取得
    
      // 対応するクイズデータのisCorrectを取得
      const userResponse = userResponses.find((response) => response.id === correspondingItem.id);
    
      return {
        [`a_${String(index + 1).padStart(3, '0')}`]: userResponse ? userResponse.isCorrect : false,
      };
    });

      //Local
      // const response = await fetch('http://localhost:8000/api/scores/', {
      //Production
      const response = await fetch('https://bitmerica.net/api/scores/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${accessToken}`,
        },
        body: JSON.stringify({
          score: score,
          quiz_foreign: quizForeign,
          ...userResponsesIsCorrect.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          // 他の必要なデータをここに追加できます
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to send score with status: ${response.status}`);
      }

      // スコアが正常に送信された場合の処理をここに追加します

    } catch (error) {
      console.error('Error sending score:', error);
    }
  };

  // スコアページを閉じる処理
  const handleScorePageClose = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      sendScore(); // accessToken が存在する場合にのみスコアを送信
    }
    navigate('/');
  };
  
  // クイズを中断する処理
  const handleQuit = () => {
    navigate('/thai_character');
  };

  return (
    <div className='quiz-app'>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <div className='quiz-section'>
            <p>Question {currentQuestion + 1} / {quizData.length}</p>
            <p style={quizTextStyle}>{quizData[currentQuestion].questionText}</p>
          </div>
          <div className='options-section'>
            {shuffleAnswerOptions().map((option, index) => (
              <button
                key={index}
                onClick={() => handleAnswerOptionClick(option.answerText)}
                className="button"
              >
                {option.answerText} 
              </button>
            ))}
            <br></br>
            <br></br>
            </div>

            <div className="button-group-home">
              <button onClick={handleQuit} className='button'>Quit</button>
              </div>
          <Modal 
            isOpen={isModalOpen}
            onRequestClose={handleNextQuestion}
            ariaHideApp={false}
            style={currentQuestion === quizData.length - 1 ? modalStyle2 : modalStyle1}
            contentLabel="モーダル"
          >
            <div className={`answer-feedback ${isCorrectPopup ? 'correct' : 'incorrect'}`}>
            {isCorrectPopup ? (
                                  <div>
                                    <p>Correct!</p>
                                  </div>
                                ) : (
                                  <div>
                                    <p>Incorrect.</p>
                                    <p>Correct Answer is "{quizData[currentQuestion].correctAnswer}" .</p>
                                  </div>
                                )}              {
                                  currentQuestion === quizData.length - 1 && (
                  <div>
                  <h2>Score</h2>
                  <p>number of correct answers: {score} / {quizData.length}</p>
                  <h2>Answer List</h2>
                  <ul>
                    {userResponses.map((response, index) => (
                      <li key={index}>
                        <p style={{ textAlign: 'left' }}>Quesion: {response.question}</p>
                        <p style={{ textAlign: 'left' }}>Selected answers: {response.selectedAnswer}</p>
                        <p style={{ textAlign: 'left' }}>
                        {response.isCorrect
                          ? 'Correct！'
                          : (
                            <div>
                              Incorrect <br />
                              Correct Answer is "{response.correctAnswer}"
                            </div>
                          )}
                        </p>
                      </li>
                    ))}
                  </ul>
                <button
                onClick={handleScorePageClose}
                className="button"
                style={{
                display: 'block', // ブロック要素として表示
                margin: '0 auto',  // 左右中央揃え
                }}
                >
                Close
                </button>
                </div>
              )}
              {currentQuestion !== quizData.length - 1 && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button onClick={handleNextQuestion} className="button">Next</button>
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ThaiCharacterQuiz;
