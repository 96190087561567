import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Words = () => {
  useEffect(() => {
    document.title = 'タイ語 食事に関する言葉';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <html>
      <body>
      <Header />
      <Banner className="words-page"/>
  
      <div className="center-thai">
        <h1 className="title-each-page">食事に関する言葉</h1>

        <div className="button-group">
        <Link to="/ja/cooking" className="button">料理</Link>  
        <Link to="/ja/cooking_quiz" className="button">確認クイズ</Link>
        </div>

        <div className="button-group">
        <Link to="/ja/daily_meal" className="button">毎日の食事</Link>  
        <Link to="/ja/daily_meal_quiz" className="button">確認クイズ</Link>
        </div>

        <div className="button-group">
        <Link to="/ja/drink" className="button">飲み物</Link>  
        <Link to="/ja/drink_quiz" className="button">確認クイズ</Link>
        </div>

        <div className="button-group">
        <Link to="/ja/fruits_seafood" className="button">果物,海鮮料理</Link>  
        <Link to="/ja/fruits_seafood_quiz" className="button">確認クイズ</Link>
        </div>

        <div className="button-group">
        <Link to="/ja/kitchen" className="button">台所</Link>  
        <Link to="/ja/kitchen_quiz" className="button">確認クイズ</Link>
        </div>

        <div className="button-group">
        <Link to="/ja/food_material" className="button">料理の材料</Link>  
        <Link to="/ja/food_material_quiz" className="button">確認クイズ</Link>
        </div>

        <div className="button-group">
        <Link to="/ja/seasoning" className="button">調味料</Link>  
        <Link to="/ja/seasoning_quiz" className="button">確認クイズ</Link>
        </div>

        <div className="button-group">
        <Link to="/ja/restraunt" className="button">レストラン</Link>  
        <Link to="/ja/restraunt_quiz" className="button">確認クイズ</Link>
        </div>

        <div className="button-group-home">
        <Link to="/ja/words" className="button">戻る</Link>
        </div>
  
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

      </body>
      </html>
    </>
    );
  };

export default Words;