import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Words = () => {
  useEffect(() => {
    document.title = 'Thai Similar verb';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <html>
      <body>
      <Header />
      <Banner className="words-page"/>
  
      <div className="center-thai">
        <h1 className="title-each-page">Thai Similar verb</h1>

        <div className="button-group">
          <Link to="/similar_verb_words" className="button">similar verb</Link>
          <Link to="/similar_verb_words_quiz" className="button">confirmation quiz</Link>
        </div>

        <div className="button-group-home">
        <Link to="/words" className="button">return</Link>
        </div>
  
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

      </body>
      </html>
    </>
    );
  };

export default Words;