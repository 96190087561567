import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const TimeHome = () => {
  useEffect(() => {
    document.title = 'タイ語 時間に関する言葉';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <html>
      <body>
      <Header />
      <Banner className="words-page"/>
  
      <div className="center-thai">
        <h1 className="title-each-page">時に関する単語</h1>
        <div className="button-group">
          <Link to="/ja/date" className="button">日付,曜日,週</Link>
          <Link to="/ja/date_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/month" className="button">月の名称</Link>
          <Link to="/ja/month_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/time" className="button">時を表す言葉</Link>
          <Link to="/ja/time_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/time_all_lower" className="button">時刻</Link>
          <Link to="/ja/time_all_lower_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/time_for_all_day" className="button">1日の時間</Link>
          <Link to="/ja/time_for_all_day_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/today_etc" className="button">今日、先週、来月</Link>
          <Link to="/ja/today_etc_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/year_and_season" className="button">年と季節</Link>
          <Link to="/ja/year_and_season_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group-home">
        <Link to="/ja/words" className="button">戻る</Link>
        </div>
  
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

      </body>
      </html>
    </>
    );
  };

export default TimeHome;