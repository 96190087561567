import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Tones = () => {
  useEffect(() => {
    document.title = 'タイ語 リーディング';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
    <html>
    <body>
    <Header />
    <Banner className="reading-page"/>

    <div className="center-thai">
      <h1 className="title-each-page">タイ文字のリーディング</h1>
      <div className="button-group">
      <Link to="/ja/reading_quiz_1" className="button">問題①</Link>
      <Link to="/ja/reading_quiz_2" className="button">問題②</Link>
      </div>      
      <div className="button-group">
      <Link to="/ja/reading_quiz_3" className="button">問題③</Link>
      <Link to="/ja/reading_quiz_4" className="button">問題④</Link>
      </div>      
      <div className="button-group">
      <Link to="/ja/reading_quiz_5" className="button">問題⑤</Link>
      <Link to="/ja/reading_quiz_6" className="button">問題⑥</Link>
      </div>      
      <div className="button-group">
      <Link to="/ja/reading_quiz_7" className="button">問題⑦</Link>
      <Link to="/ja/reading_quiz_8" className="button">問題⑧</Link>
      </div>      
      <div className="button-group">
      <Link to="/ja/reading_quiz_9" className="button">問題⑨</Link>
      <Link to="/ja/reading_quiz_10" className="button">問題⑩</Link>
      </div>      
      <br></br>
      <div className="button-group-home">
      <Link to="/ja" className="button">HOME</Link>
      </div>

    </div>
    {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </body>
    </html>
    </>
  );
};

export default Tones;