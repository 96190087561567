import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';

const Contact = () => {
  useEffect(() => {
    document.title = 'Contact';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // メールアドレスのバリデーション関数
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // バリデーションを実行
    const validationErrors = {};

    if (formData.name.trim() === '') {
      validationErrors.name = 'Please input Name';
    }

    if (formData.email.trim() === '') {
      validationErrors.email = 'Please input email address';
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = 'Please input valid email address';
    }

    if (formData.message.trim() === '') {
      validationErrors.message = 'Please input your inquiry';
    }

    setErrors(validationErrors);

    // バリデーションが成功したらAPIにPOSTリクエストを送信
    if (Object.keys(validationErrors).length === 0) {
      try {
        setIsSubmitting(true);
        const response = await axios.post('https://bitmerica.net/api/contact/', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('Your request was successful!:', response.data);
        alert('Your Inquiry transmission Completed: We will reply to you after reviewing the contents.', response.data);
        // フォームをクリア
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      } catch (error) {
        console.error('Failed to send your inquiry:', error);
        alert('Failed to send your inquiry');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <Header />
      <Banner className="contact-page" />
  <div className="input-container">
        <h2>ContactForm</h2>

  <form className="input-container" onSubmit={handleSubmit}>
  <div className="input-field">
    <label>Name:</label>
    <input
      type="text"
      name="name"
      value={formData.name}
      onChange={handleChange}
    />
    {errors.name && <div className="error-message">{errors.name}</div>}
    <label>Email:</label>
    <input
      type="email"
      name="email"
      value={formData.email}
      onChange={handleChange}
    />
    {errors.email && <div className="error-message">{errors.email}</div>}
    <label>Your Inquiry:</label>
    <textarea
      name="message"
      value={formData.message}
      onChange={handleChange}
      rows={15} // 5行に設定
      style={{ width: '100%' }} // 幅を100%に設定
    />
    {errors.message && <div className="error-message">{errors.message}</div>}
  </div>
  <div className="input-field">
    <button type="submit" disabled={isSubmitting}>
      Send
    </button>
  </div>
</form>
</div>  

      <div className="center-thai">
        <div className="button-group-home">
          <Link to="/" className="button">HOME</Link>
        </div>
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </>
  );
};

export default Contact;
