import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';

const Login = () => {
  useEffect(() => {
    document.title = 'Change Mail';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [reNewEmail, setReNewEmail] = useState('');
  const { uid, token } = useParams();
  const [activationStatus, setActivationStatus] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
  

  return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleActivation = async () => {
    const accessToken = localStorage.getItem('accessToken');

    try {
      const response = await axios.post(
        //Local
        // 'http://localhost:8000/api/auth/users/reset_email_confirm/',
        //Production
        'https://bitmerica.net/api/auth/users/reset_email_confirm/',
        {
          uid: uid,
          token: token,
          new_email: newEmail,
          re_new_email: reNewEmail,
        },
        {
          headers: {
            'Authorization': `JWT ${accessToken}`,
          },
        }
      );
      alert('Successfully changed registered email');
      window.location.href = '/'; // ホームページにリダイレクト
    } catch (error) {
      alert('unable to change registered email.');
    }
  };

  const handleActivationClick = () => {
    handleActivation();
  };

  return (
    <>
      <Header />
      <Banner className="user-page" />

      <div className="input-container">
        <h2>Change Registered Email</h2>
        <div className="input-field">
          <label>new mail address:</label>
          <input
            type="mail"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </div>
        <div className="input-field">
          <label>new mail address(again):</label>
          <input
            type="mail"
            value={reNewEmail}
            onChange={(e) => setReNewEmail(e.target.value)}
          />
        </div>
        <div className="input-field">
          <button onClick={handleActivationClick}>change mail address</button>
        </div>
        {activationStatus && <p>{activationStatus}</p>}
      </div>

      <div className="center-thai">
        <div className="button-group-home">
          <Link to="/" className="button">HOME</Link>
        </div>
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </>
  );
};

export default Login;
