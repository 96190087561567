import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';

const Login = () => {
  useEffect(() => {
    document.title = 'ユーザ登録';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password2nd, setPassword2nd] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({}); // バリデーションエラーを管理するステート

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // バリデーションロジック
    if (!isValidEmail(newEmail)) {
      setErrors({ ...errors, email: '有効なメールアドレスを入力してください' });
    } else {
      setErrors({ ...errors, email: '' }); // エラーメッセージをクリア
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // バリデーションロジック
    if (newPassword.length < 8) {
      setErrors({ ...errors, password: 'パスワードは8文字以上で入力してください' });
    } else {
      setErrors({ ...errors, password: '' }); // エラーメッセージをクリア
    }
  };

  const handlePasswordChange2nd = (e) => {
    const newPassword2nd = e.target.value;
    setPassword2nd(newPassword2nd);

    // パスワードの一致を確認するバリデーションロジック
    if (newPassword2nd !== password) {
      setErrors({ ...errors, password2nd: 'パスワードが一致しません' });
    } else {
      setErrors({ ...errors, password2nd: '' }); // エラーメッセージをクリア
    }
  };

  const handleLogin = () => {
    if (email && password && password2nd && name) {
      // エラーメッセージが存在しないことを確認
      if (Object.values(errors).every((error) => error === '')) {
        axios
          //Local
          // .post('http://localhost:8000/api/auth/users/', {
          //Production
          .post('https://bitmerica.net/api/auth/users/', {
            name: name,
            email: email,
            password: password,
            re_password: password2nd,
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            console.log('ユーザ登録成功');
            console.log('サーバーからの応答:', response.data);
            alert('仮登録完了、メールを確認してください');
            window.location.href = '/'; // ホームページにリダイレクト
          })
          .catch((error) => {
            console.error('ユーザ登録エラー:', error);
            alert('入力情報に誤りがあります');
          });
      } else {
        console.log('入力情報を確認してください');
        alert('入力情報を確認してください');
      }
    } else {
      console.log('入力情報を確認してください');
      alert('入力情報を確認してください');
    }
  };

  // メールアドレスのバリデーション関数
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  return (
    <>
      <Header />
      <Banner className="register-page" />

      <div className="input-container">
          <h2>ユーザ登録</h2>
          <p>必須事項に入力をお願いします</p>
          <div className="input-field">
            <label>User:</label>
            <input type="text" value={name} onChange={handleName} />
          </div>
          <div className="input-field">
            <label>Email:</label>
            <input type="email" value={email} onChange={handleEmailChange} />
            <div className="error-message">{errors.email}</div>
          </div>
          <div className="input-field">
            <label>Password:</label>
            <input type="password" value={password} onChange={handlePasswordChange} />
            <div className="error-message">{errors.password}</div>
          </div>
          <div className="input-field">
            <label>Password(確認用):</label>
            <input type="password" value={password2nd} onChange={handlePasswordChange2nd} />
            <div className="error-message">{errors.password2nd}</div>
          </div>
          <div className="input-field">
            <button onClick={handleLogin}>送信</button>
          </div>
      </div>

      <div className="center-thai">
        <div className="button-group-home">
          <Link to="/ja" className="button">HOME</Link>
        </div>
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </>
  );
};

export default Login;
