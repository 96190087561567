import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

const Question = ({ question, playAudio }) => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  // コンポーネントがマウントされたときに自動的に音声を再生
  // useEffect(() => {
  //   playAudio(question.listeningPath);
  // }, [playAudio, question.listeningPath]);

// Question コンポーネント内の useEffect で再生後に状態を設定
useEffect(() => {
  if (isAudioPlaying) {
    playAudio(question.listeningPath); // ボタンを押すと再生
  }
}, [isAudioPlaying, playAudio, question.listeningPath]);

  return (
    <div className='quiz-section'>
      <h2>Quiz</h2>
      <p>{question.questionText}</p>
      <button onClick={() => playAudio(question.listeningPath)} disabled={isAudioPlaying}>
        Audio
      </button>
    </div>
  );
};

const AnswerOptions = ({ options, handleAnswerOptionClick }) => {
  return (
    <div className='options-section'>
      {options.map((option, index) => (
        <div key={index}>
          <button className="button" onClick={() => handleAnswerOptionClick(option.answerText)}>
            {option.answerText}
          </button>
        </div>
      ))}
    </div>
  );
};

const modalStyle1 = {
  content: {
    width: '300px',
    height: '200px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const modalStyle2 = {
  content: {
    width: '300px',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
};

export const Quiz_Listening = ({ quizDataPath, quizForeign }) => {
  const [quizData, setQuizData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userResponses, setUserResponses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCorrectPopup, setIsCorrectPopup] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const response = await fetch(quizDataPath);
        if (!response.ok) {
          throw new Error(`Fetch failed with status: ${response.status}`);
        }
        const data = await response.json();

        const shuffledData = shuffleArray(data.map((question, index) => ({ ...question, index })));
        setQuizData(shuffledData);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching quiz data:', error);
        setIsLoading(false);
      }
    };

    fetchQuizData();
  }, [quizDataPath]);

  useEffect(() => {
    if (window.AudioContext) {
      const audioCtx = new AudioContext();
      setAudioContext(audioCtx);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (audioContext) {
        audioContext.close();
      }
    };
  }, [audioContext]);

  useEffect(() => {
    if (audioContext) {
      const loadAudio = async (audioPath) => {
        try {
          const response = await fetch(audioPath);
          const audioData = await response.arrayBuffer();
          const buffer = await audioContext.decodeAudioData(audioData);
          setAudioBuffer(buffer);
        } catch (error) {
          console.error('Error loading audio:', error);
        }
      };

      if (currentAudio) {
        loadAudio(currentAudio.listeningPath);
      }
    }
  }, [audioContext, currentAudio]);

const playAudio = async (audioPath) => {
  if (audioContext) {
    try {
      await audioContext.resume(); // オーディオコンテキストを再開

      const response = await fetch(audioPath);
      const data = await response.arrayBuffer();
      const buffer = await audioContext.decodeAudioData(data);

      const source = audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(audioContext.destination);
      source.start();
    } catch (error) {
      console.error("Error playing audio: ", error);
    }
  }
};

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };
  const handleAnswerOptionClick = (selectedAnswer) => {
    if (!isAudioPlaying) { // 音声再生中でない場合のみクリックを許可
      const isCorrect = selectedAnswer === quizData[currentQuestion].correctAnswer;
  
      setUserResponses([
        ...userResponses,
        {
          id: quizData[currentQuestion].id,
          question: quizData[currentQuestion].questionText,
          selectedAnswer,
          isCorrect,
          correctAnswer: quizData[currentQuestion].correctAnswer,
        },
      ]);
  
      if (isCorrect) {
        setScore(score + 1);
      }
  
      setIsCorrectPopup(isCorrect);
      setIsModalOpen(true);
    }
  };
  

  const handleNextQuestion = () => {
    setIsModalOpen(false);

    if (currentQuestion === quizData.length - 1) {
      setIsModalOpen(true);
    } else {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

const sendScore = async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    
    if (!accessToken) {
      // accessToken が存在しない場合はスコアを送信しない
      console.warn('No access token found. Score not sent.');
      return;
    }

   // ユーザーの回答の正誤情報をquizDataの昇順で取得
   const userResponsesIsCorrect = quizData.map((item, index) => {
    const sortedQuizData = [...quizData].sort((a, b) => a.id - b.id); // idを昇順にソートした新しい配列を作成
    const correspondingItem = sortedQuizData[index]; // ソート済み配列から現在のindexに対応するクイズデータを取得
  
    // 対応するクイズデータのisCorrectを取得
    const userResponse = userResponses.find((response) => response.id === correspondingItem.id);
  
    return {
      [`a_${String(index + 1).padStart(3, '0')}`]: userResponse ? userResponse.isCorrect : false,
    };
  });

    //Local
    // const response = await fetch('http://localhost:8000/api/scores/', {
    //Production
    const response = await fetch('https://bitmerica.net/api/scores/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${accessToken}`,
      },
      body: JSON.stringify({
        score: score,
        quiz_foreign: quizForeign,
        ...userResponsesIsCorrect.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        // 他の必要なデータをここに追加できます
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to send score with status: ${response.status}`);
    }

    // スコアが正常に送信された場合の処理をここに追加します

  } catch (error) {
    console.error('Error sending score:', error);
  }
};

  const handleScorePageClose = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      sendScore();
    }
    navigate('/tones');
  };

  const handleQuit = () => {
    navigate('/tones');
  };

  return (
    <div className='quiz-app'>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className='quiz-section'>
          <p>Question {currentQuestion + 1}/{quizData.length}</p>
          {/* 現在の質問が自動で再生されるようにする */}
          <Question question={quizData[currentQuestion]} playAudio={playAudio} />
          <br />
          <div className='tone-button'>
          <AnswerOptions options={quizData[currentQuestion].answerOptions} handleAnswerOptionClick={handleAnswerOptionClick} />
          </div>
          <br></br>
          <br></br>
          <div style={buttonContainerStyle}>
            <button className="button" onClick={handleQuit}>Quit</button>
          </div>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={handleNextQuestion}
            ariaHideApp={false}
            style={currentQuestion === quizData.length - 1 ? modalStyle2 : modalStyle1}
          >
            <div className={`answer-feedback ${isCorrectPopup ? 'correct' : 'incorrect'}`}>
            {isCorrectPopup ? (
                                  <div>
                                    <p>Correct!</p>
                                  </div>
                                ) : (
                                  <div>
                                    <p>Incorrect.</p>
                                    <p>Correct Answer is "{quizData[currentQuestion].correctAnswer}" .</p>
                                  </div>
                                )}              {
                                  currentQuestion === quizData.length - 1 && (
                  <div>
                  <h2>Score</h2>
                  <p>Number of Correct: {score} / {quizData.length}</p>
                  <h2>Answer List</h2>
                  <ul>
                    {userResponses.map((response, index) => (
                      <li key={index}>
                        <p>Question: {response.question}</p>
                        <p>Selected answers: {response.selectedAnswer}</p>
                        {response.isCorrect
                          ? 'Correct！'
                          : (
                            <div>
                              Incorrect <br />
                              Correct Answer is "{response.correctAnswer}"
                            </div>
                          )}
                      </li>
                    ))}
                  </ul>
                  <div style={buttonContainerStyle}>
                    <button onClick={handleScorePageClose} className="button">Close</button>
                  </div>
                </div>
              )}
              {currentQuestion !== quizData.length - 1 && (
                <div style={buttonContainerStyle}>
                  <button onClick={handleNextQuestion} className="button">Next</button>
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Quiz_Listening;
