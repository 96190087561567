import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './App.css'; // スタイルシートをインポート
// import { appCssFile } from './App_CssFile;
import Header from './header_JP';
import Banner from './banner';
import Footer from './footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Home = () => {
  useEffect(() => {
    document.title = 'タイ語学習のナインアローギークス';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
  <>
  {/* <HelmetProvider>
    <Helmet>
      <meta name="description" content="タイ文字に関する説明文" />
      <link rel="apple-touch-icon" href="/ninearrowsgeeks.jpg" />
    </Helmet>
  </HelmetProvider> */}
    <html>
    <body>
      <Header />
      <Banner className="home-page-JP" />
      <h2 class="home-heading">9AGのタイ語オンライン学習カテゴリー</h2>
      <div className="center-content">
        {/* コンテンツ */}
        <div className="l-wrapper_06">
          <div className="card_06">
            <img className="card-img_06" src="/images/thai-character3.jpg" alt="thai-character" />
            <div className="card-content_06">
              <p className="card-title_06">タイ文字</p>
              <p className="card-text_06">42の子音と母音からなるタイ文字。タイ文字を理解できればタイでの解像度が上がり、タイがもっと楽しくなる！</p>
            </div>
            <div className="card-link_06">
              <Link to="/ja/Thai_Character">学習開始</Link><br />
            </div>
          </div>
        </div>

        <div className="l-wrapper_06">
          <div className="card_06">
            <img className="card-img_06" src="/images/words.jpg" alt="words" />
            <div className="card-content_06">
              <p className="card-title_06">単語</p>
              <p className="card-text_06">単語を覚えることはそのままタイ語スキルの向上を意味します。一つでも多くの単語を覚えてタイを楽しもう！</p>
            </div>
            <div className="card-link_06">
              <Link to="/ja/words">学習開始</Link><br />
            </div>
          </div>
        </div>

        <div className="l-wrapper_06">
          <div className="card_06">
            <img className="card-img_06" src="/images/tones.jpg" alt="tones" />
            <div className="card-content_06">
              <p className="card-title_06">声調</p>
              <p className="card-text_06">タイ語を勉強、使用する上で最も重要なのがタイ語の声調。声調をマスターしたらタイ語はもうお手の物かも!?</p>
            </div>
            <div className="card-link_06">
              <Link to="/ja/Tones">学習開始</Link><br />
            </div>
          </div>
        </div>

        <div className="l-wrapper_06">
          <div className="card_06">
            <img className="card-img_06" src="/images/grammar.jpg" alt="grammar" />
            <div className="card-content_06">
              <p className="card-title_06">文法</p>
              <p className="card-text_06">S+V+Cなど馴染みやすいルールと自制などによる単語の変化がない分かりやすいのがタイ語の文法ルールの特徴！</p>
            </div>
            <div className="card-link_06">
              <Link to="/ja/Grammar">学習開始</Link><br />
            </div>
          </div>
        </div>
        <div className="l-wrapper_06">
          <div className="card_06">
            <img className="card-img_06" src="/images/listening.jpg" alt="grammar" />
            <div className="card-content_06">
              <p className="card-title_06">リスニング</p>
              <p className="card-text_06">タイ人との会話で必須のリスニング、声調をある程度理解したらリスニングにもトライして上を目指そう！</p>
            </div>
            <div className="card-link_06">
              <Link to="/ja/listening">学習開始</Link><br />
            </div>
          </div>
        </div>
        <div className="l-wrapper_06">
          <div className="card_06">
            <img className="card-img_06" src="/images/reading.jpg" alt="grammar" />
            <div className="card-content_06">
              <p className="card-title_06">リーディング</p>
              <p className="card-text_06">タイ上級者への道はタイ語のタイ文字リーディングにあり！タイ文字が読めれば他の人とも差がつくのは間違いなし!</p>
            </div>
            <div className="card-link_06">
              <Link to="/ja/reading">学習開始</Link><br />
            </div>
          </div>
        </div>
    </div>

    <h2 class="home-heading">9AGのタイ語オンライン学習サイトについて</h2>
    <div className="center-content">
        <div class="card-container2">


        <div class="card2 card-skin">
        <div class="card2__imgframe_5"></div>
        <div class="card2__textbox">
        <Link to="index" className='link-text'>  
          <div class="card2__titletext">
            インデックス
          </div>
        </Link>
          <div class="card2__overviewtext">
          9AGのIndexはタイ語学習をサポートするための便利な逆引き表です。このIndexページでは、単語やフレーズを集め、整理し、学習プロセスを効果的に支援します。
          </div>
        </div>
        </div>

        <div class="card2 card-skin">
        <div class="card2__imgframe_1"></div>
        <div class="card2__textbox">
        <Link to="/ja/ninearrowsgeeks" className='link-text'>
          <div class="card2__titletext">
            9AGとは？
          </div>
        </Link>
        <div class="card2__overviewtext">
        9AG(Nine Arrows Geeks)は、タイ語に特化したプレミアムオンライン学習プラットフォームです。タイの生活をもっと楽しむためにタイ語を学ぶことをモットーとしています。
          </div>
        </div>
        </div>

        <div class="card2 card-skin">
        <div class="card2__imgframe_youtube"></div>
        <div class="card2__textbox">
        <Link to="https://www.youtube.com/channel/UCgFMXYKvTSnXVVqQ_EnIW6A" className='link-text'>  
          <div class="card2__titletext">
            Youtube
          </div>
        </Link>
          <div class="card2__overviewtext">
          9AGはまた、タイ語でよく使われる表現に焦点を当てたビデオレッスンのYouTubeチャンネルなど、学習に役立つコンテンツをYouTubeで提供しています。
          </div>
        </div>
        </div>

        <div class="card2 card-skin">
        <div class="card2__imgframe_mobile"></div>
        <div class="card2__textbox">
        <Link to="/ja/mobile" className='link-text'>  
          <div class="card2__titletext">
            モバイルアプリ
          </div>
        </Link>
          <div class="card2__overviewtext">
          9AGモバイルアプリでも学習コンテンツのアプリを提供予定です。アプリとウェブサイト上の学習進捗はシームレスに同期されます。
          </div>
        </div>
        </div>


        </div>

      </div>

          <div className="center-content">
        <div class="card-container2">



        <div class="card2 card-skin">
        <div class="card2__imgframe_2"></div>
        <div class="card2__textbox">
        <Link to="/ja/privacypolicy" className='link-text'>  
          <div class="card2__titletext">
            個人情報保護方針
          </div>
        </Link>
          <div class="card2__overviewtext">
          9AGでは、個人情報保護を真摯に受け止め、最新のIT技術、セキュリティを使って情報保護に最善を尽くしています。ユーザー情報は厳重に管理いたします。
          </div>
        </div>
        </div>

        <div class="card2 card-skin">
        <div class="card2__imgframe_4"></div>
        <div class="card2__textbox">
        <Link to="index" className='link-text'>  
          <div class="card2__titletext">
            ログイン
          </div>
        </Link>
          <div class="card2__overviewtext">
          ユーザー登録が完了するとスコア管理などが可能になります。ログインはこちらからアクセスしてください。
          </div>
        </div>
        </div>

        <div class="card2 card-skin">
        <div class="card2__imgframe_register"></div>
        <div class="card2__textbox">
        <Link to="/ja/login" className='link-text'>  
          <div class="card2__titletext">
            ユーザー登録
          </div>
        </Link>
          <div class="card2__overviewtext">
          9AGにユーザー登録すれば、勉強した内容を9AGに保存でき、効率よくタイ語を勉強できるように設計しています。今後はAIによるアドバイス機能も実装予定です。
          </div>
        </div>
        </div>

        <div class="card2 card-skin">
        <div class="card2__imgframe_3"></div>
        <div class="card2__textbox">
        <Link to="/ja/contactform" className='link-text'> 
          <div class="card2__titletext">
            お問合せ
          </div>
          </Link>
          <div class="card2__overviewtext">
          ナインアローズギークスでのオンライン学習体験をより良いものにするために常に心がけています。ご質問がございましたら、お気軽にお問い合わせください。
          </div>
        </div>
        </div>

        </div>

      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </body>
    </html>
  </>
  );
};

export default Home;
