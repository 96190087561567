import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const TimeHome = () => {
  useEffect(() => {
    document.title = 'Thai Words of Time';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <html>
      <body>
      <Header />
      <Banner className="words-page"/>
  
      <div className="center-thai">
        <h1 className="title-each-page">Words of Time</h1>
        <div className="button-group">
          <Link to="/date" className="button">date</Link>
          <Link to="/date_quiz" className="button">Confirmation Quiz</Link>
        </div>
        <div className="button-group">
          <Link to="/month" className="button">month</Link>
          <Link to="/month_quiz" className="button">Confirmation Quiz</Link>
        </div>
        <div className="button-group">
          <Link to="/time" className="button">time</Link>
          <Link to="/time_quiz" className="button">Confirmation Quiz</Link>
        </div>
        <div className="button-group">
          <Link to="/time_all_lower" className="button">time all lower</Link>
          <Link to="/time_all_lower_quiz" className="button">Confirmation Quiz</Link>
        </div>
        <div className="button-group">
          <Link to="/time_for_all_day" className="button">time for a day</Link>
          <Link to="/time_for_all_day_quiz" className="button">Confirmation Quiz</Link>
        </div>
        <div className="button-group">
          <Link to="/today_etc" className="button">today,this week,etc</Link>
          <Link to="/today_etc_quiz" className="button">Confirmation Quiz</Link>
        </div>
        <div className="button-group">
          <Link to="/year_and_season" className="button">seasons</Link>
          <Link to="/year_and_season_quiz" className="button">Confirmation Quiz</Link>
        </div>
        <div className="button-group-home">
        <Link to="/words" className="button">Return</Link>
        </div>
  
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

      </body>
      </html>
    </>
    );
  };

export default TimeHome;