import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';

const Login = () => {
  useEffect(() => {
    document.title = 'Login';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    if (email && password) {
      axios
        //Local
        // .post('http://localhost:8000/api/auth/jwt/create', {
        //Production
        .post('https://bitmerica.net/api/auth/jwt/create', {
          email: email,
          password: password,
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          console.log('Login Success');
          console.log('Server response:', response.data);
          // アクセストークンとリフレッシュトークンをローカルストレージに保存
          localStorage.setItem('accessToken', response.data.access);
          localStorage.setItem('refreshToken', response.data.refresh);
          alert('Login Success');
          window.location.href = '/'; // ログアウト後にホームページにリダイレクト
        })
        .catch((error) => {
          console.error('Login Error:', error);
          alert('Login Error');
        });
    } else {
      console.log('Please confirm inputted info');
      alert('Please confirm inputted info');
    }
  };

  return (
    <>
      <Header />
      <Banner className="user-page" />

      <div className="input-container">
          <h2>Login</h2>
          <div className="input-field">
            <label>Email:</label>
            <input type="text" value={email} onChange={handleEmailChange} />
          </div>
          <div className="input-field">
            <label>Password:</label>
            <input type="password" value={password} onChange={handlePasswordChange} />
          </div>
          <div className="input-field">
            <button onClick={handleLogin}>Login</button>
          </div>
          <p>If you are not a registered user, please <Link to="/register">Register</Link></p>

          {/* <p>パスワード変更は<Link to="/passwordChange">こちら</Link></p> */}
          {/* <p>パスワード忘れた場合は<Link to="/passwordReset">こちら</Link></p> */}
      </div>

      <div className="center-thai">
        <div className="button-group-home">
          <Link to="/" className="button">HOME</Link>
        </div>
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </>
  );
};

export default Login;
