import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';

const Login = () => {
  useEffect(() => {
    document.title = 'パスワード変更';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [reNewPassword, setReNewPassword] = useState('');
  const { uid, token } = useParams();
  const [activationStatus, setActivationStatus] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
  

  return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleActivation = async () => {
    const accessToken = localStorage.getItem('accessToken');

    try {
      const response = await axios.post(
        //Local
        // 'http://localhost:8000/api/auth/users/reset_password_confirm/',
        //Production
        'https://bitmerica.net/api/auth/users/reset_password_confirm/',
        {
          uid: uid,
          token: token,
          new_password: newPassword,
          re_new_password: reNewPassword,
        },
        {
          headers: {
            'Authorization': `JWT ${accessToken}`,
          },
        }
      );
      alert('パスワード変更が成功しました。');
      window.location.href = '/'; // ホームページにリダイレクト
    } catch (error) {
      alert('パスワード変更が出来ませんでした。');
    }
  };

  const handleActivationClick = () => {
    handleActivation();
  };

  return (
    <>
      <Header />
      <Banner className="user-page" />

      <div className="input-container">
        <h2>パスワード変更</h2>
        <div className="input-field">
          <label>新しいパスワード:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="input-field">
          <label>新しいパスワード（再入力）:</label>
          <input
            type="password"
            value={reNewPassword}
            onChange={(e) => setReNewPassword(e.target.value)}
          />
        </div>
        <div className="input-field">
          <button onClick={handleActivationClick}>パスワード変更</button>
        </div>
        {activationStatus && <p>{activationStatus}</p>}
      </div>

      <div className="center-thai">
        <div className="button-group-home">
          <Link to="/ja" className="button">HOME</Link>
        </div>
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </>
  );
};

export default Login;
