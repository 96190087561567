import React, { useState, useEffect } from "react";
import Words_Basic_Logic from '../words_basic_logic'; // Words コンポーネントをインポート
import Header from '../../header';
import Footer from '../../footer';
import '../../App.css';

const Basic_Verb = () => {
  useEffect(() => {
    document.title = 'タイ単語';
  }, []);


  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <body>
      <Header />
      <div className="center-content3">
      <Words_Basic_Logic dataPath='/json/words/body/health.json' />
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

      </div>
      </body>
    </div>
  );
};

export default Basic_Verb;
