import React, { useState, useEffect } from "react";
import Basic_Listening_Quiz from './basic_listening_quiz';
import Header from '../header';
import Footer from '../footer';
import '../App.css';

export const Listening_Quiz_1 = () => {
    // Quiz コンポーネントを使用
    useEffect(() => {
      document.title = 'Thai Listening Quiz';
    }, []);

    const [shouldHideFooter, setShouldHideFooter] = useState(false);

    useEffect(() => {
      // スクロールイベントリスナーを追加
      const handleScroll = () => {
        // スクロール位置を取得
        const scrollY = window.scrollY;
  
        // スクロール位置が1000pxを超えた場合にフッターを非表示にする
        if (scrollY >= 30) {
          setShouldHideFooter(true);
        } else {
          setShouldHideFooter(false);
        }
      };
  
      // スクロールイベントリスナーを追加
      window.addEventListener('scroll', handleScroll);
  
      // コンポーネントがアンマウントされたときにイベントリスナーを削除
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  // Quiz コンポーネントを使用
  return (
    <div>
      <body>
      <Header />
      <div className="center-content3">
      <Basic_Listening_Quiz quizDataPath='/json/listening/meal_quiz_JP.json' quizForeign={83}/>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
      </div>
      </body>
    </div>
  );
};

export default Listening_Quiz_1;