import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import '../App.css';

const ThaiCharacter = () => {
  useEffect(() => {
    document.title = 'Thai Character';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
      <html>
        <body>
          <Header />
          <Banner className="thai-character-page" />

          <div className="center-thai">
            <h1 className="title-each-page">Thai Character</h1>
            <div className="button-group">
              <Link to="/consonant" className="button">Consonant</Link>
              <Link to="/consonant_quiz" className="button">Consonant Quiz</Link>
            </div>
            <div className="button-group">
              <Link to="/vowel" className="button">Vowel</Link>
              <Link to="/vowel_quiz" className="button">Vowel Quiz</Link>
            </div>
            <div className="button-group">
              <Link to="/diphthong" className="button">Diphthong</Link>
              <Link to="/diphthong_quiz" className="button">Diphthong Quiz</Link>
            </div>
            <br></br>

            <div className="button-group-home">
              <Link to="/" className="button">HOME</Link>
            </div>

          </div>
          {shouldHideFooter ? null : <Footer className="custom-footer" />}
        </body>
      </html>
  );
};

export default ThaiCharacter;
