import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';

const Login = () => {
  useEffect(() => {
    document.title = 'ユーザ情報変更';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);
  const [userData, setUserData] = useState(null);
  
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const [newPassword, setNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [newUser, setNewUser] = useState(''); // 新しいユーザ名のステート

  // ローカルストレージからJWTアクセストークンを取得
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();


  useEffect(() => {
    // ローカルストレージからJWTアクセストークンを取得
    const accessToken = localStorage.getItem('accessToken');

    // アクセストークンが存在する場合、DjangoのAPIからユーザーデータを取得
    if (accessToken) {
      //Local
      // axios.get('http://localhost:8000/api/auth/users/me/', {
      //Production
      axios.get('https://bitmerica.net/api/auth/users/me/', {
        headers: {
          'Authorization': `JWT ${accessToken}`,
        },
      })
      .then(response => {
        setUserData(response.data);
      })
      .catch(error => {
        console.error('ユーザーデータの取得に失敗しました', error);
      });
    }
  }, []);

  const NewUserName = () => {
    if (userData && userData.email) {
      const accessToken = localStorage.getItem('accessToken');
      
      //Local
      // axios.patch('http://localhost:8000/api/auth/users/me/', {
      //Production
      axios.patch('https://bitmerica.net/api/auth/users/me/', {
        name: newUser,
      }, {
        headers: {
          'Authorization': `JWT ${accessToken}`,
        },
      })
      .then(response => {
        // パスワードリセットリクエストが成功した場合の処理
        console.log('ユーザ名変更のリクエストが成功しました。');
        alert('ユーザ名変更のが成功しました');
        window.location.href = '/'; // ホームページにリダイレクト
      })
      .catch(error => {
        // エラーハンドリング
        console.error('ユーザ名変更のに失敗しました', error);
        alert('ユーザ名変更のに失敗しました。エラー: ' + error.message);
      });
    } else {
      alert('ユーザーデータが不足しています。');
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= 255) {
      setNewUser(value);
      setErrorMessage(''); // エラーメッセージをクリア
    } else {
      setErrorMessage('255文字以内にしてください');
    }
  };
  return (
    <>
      <Header />
      <Banner className="user-page" />

      <div className="input-container">
      <h2>ユーザ情報変更</h2>
      <div className="input-field">
        <label>新しいユーザ名:</label>
        <input
          type="text"
          value={newUser}
          onChange={(e) => setNewUser(e.target.value)} 
        />
      </div>

      <div className="input-field">
      <button onClick={NewUserName}>ユーザ名変更</button>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>

    </div>

      <div className="center-thai">
        <div className="button-group-home">
          <Link to="/ja" className="button">HOME</Link>
        </div>
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </>
  );
};

export default Login;
