import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom'; // react-router-domからLinkをインポート

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = window.innerWidth <= 500;

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 500;
      if (mobile !== isMobile) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  // ログイン状態をローカルストレージから取得
  const isLoggedIn = !!localStorage.getItem('accessToken');

  // ダミーのログアウト関数
  const handleLogout = () => {
    // ローカルストレージからアクセストークンを削除
    localStorage.removeItem('accessToken');
    // ログアウト後にログイン状態を更新
    window.location.href = '/'; // ログアウト後にホームページにリダイレクト
  };

  return (
    <header>
      <Link className="link-line-delete" to="/">
        <img className="header-image" src="/images/9ag-logo-noname.jpg" alt="NINE ARROWS GEEKS" />
      </Link>
      <h1 className="title">
        <Link className="link-line-delete" to="/">
          <div className="header-item">Nine Arrows Geeks</div>
        </Link>
      </h1>

      {isMobile ? (
        <div className="bm-burger-button" onClick={() => setMenuOpen(!menuOpen)}>
        </div>
      ) : null}

      {isMobile ? (
        <Menu right width={'35%'} isOpen={menuOpen} onStateChange={({ isOpen }) => setMenuOpen(isOpen)} disableAutoFocus>
          <ul>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/" onClick={closeMenu}>
                <div className="header-item-hamburger">Home</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/Thai_Character" onClick={closeMenu}>
                <div className="header-item-hamburger">Thai Character</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/Words" onClick={closeMenu}>
                <div className="header-item-hamburger">Words</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/Tones" onClick={closeMenu}>
                <div className="header-item-hamburger">Tones</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/Grammar" onClick={closeMenu}>
                <div className="header-item-hamburger">Grammar</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/listening" onClick={closeMenu}>
                <div className="header-item-hamburger">Listening</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/Reading" onClick={closeMenu}>
                <div className="header-item-hamburger">Reading</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/Index" onClick={closeMenu}>
                <div className="header-item-hamburger">Words Index</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ninearrowsgeeks" onClick={closeMenu}>
                <div className="header-item-hamburger">9AG</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/privacypolicy" onClick={closeMenu}>
                <div className="header-item-hamburger">PrivacyPolicy</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/contactform" onClick={closeMenu}>
                <div className="header-item-hamburger">Contact</div>
              </Link>
            </li>
            {isLoggedIn ? (
              <>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/dashboard" onClick={closeMenu}>
                    <div className="header-item-hamburger">Dashboard</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/" onClick={closeMenu}>
                    <div className="header-item-hamburger">EN</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/ja" onClick={closeMenu}>
                    <div className="header-item-hamburger">JP</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <div className="close" onClick={closeMenu}>
                    <span className="header-item-hamburger" onClick={handleLogout}>LogOut</span>
                  </div>
                </li>

              </>
            ) : (
              <>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/login" onClick={closeMenu}>
                    <div className="header-item-hamburger">Login</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/register" onClick={closeMenu}>
                    <div className="header-item-hamburger">Register</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/" onClick={closeMenu}>
                    <div className="header-item-hamburger">EN</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/ja" onClick={closeMenu}>
                    <div className="header-item-hamburger">JP</div>
                  </Link>
                </li>
              </>
            )}
            <li className="menu-item-header">
              <div className="close" onClick={closeMenu}>
                <span className="header-item-hamburger">Close</span>
              </div>
            </li>
          </ul>
        </Menu>
      ) : (
        <nav className="normal-menu">
          <ul>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/Thai_Character">
                <div className="header-item">Thai Character</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/Words">
                <div className="header-item">Words</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/Tones">
                <div className="header-item">Tones</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/Grammar">
                <div className="header-item">Grammar</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/listening">
                <div className="header-item">Listening</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/reading">
                <div className="header-item">Reading</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/index">
                <div className="header-item">Index</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/ninearrowsgeeks">
                <div className="header-item">9AG</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/privacypolicy">
                <div className="header-item">PrivacyPolicy</div>
              </Link>
            </li>
            <li className="menu-item-header">
              <Link className="link-line-delete" to="/contactform">
                <div className="header-item">Contact</div>
              </Link>
            </li>
            <li className="menu-item-header">
                  <Link className="link-line-delete" to="/">
                    <div className="header-item">EN</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/ja">
                    <div className="header-item">JP</div>
                  </Link>
                </li>
            {isLoggedIn ? (
              <>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/dashboard">
                    <div className="header-item">Dashboard</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <div className="header-item" onClick={handleLogout}>LogOut</div>
                </li>
              </>
            ) : (
              <>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/login">
                    <div className="header-item">Login</div>
                  </Link>
                </li>
                <li className="menu-item-header">
                  <Link className="link-line-delete" to="/register">
                    <div className="header-item">Register</div>
                  </Link>
                </li>
                
              </>
            )}
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
