import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Words = () => {
  useEffect(() => {
    document.title = 'タイ語 場所に関する言葉';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <html>
      <body>
      <Header />
      <Banner className="words-page"/>
  
      <div className="center-thai">
        <h1 className="title-each-page">場所に関する言葉</h1>
        <div className="button-group-home">
        <Link to="/ja/words" className="button">戻る</Link>
        </div>
  
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

      </body>
      </html>
    </>
    );
  };

export default Words;