import React from 'react';
import { BrowserRouter,Routes, Route} from 'react-router-dom';
//HOME画面
import Home from './HOME';
import Home_JP from './HOME_JP';
// import BTCTHB from './BTCTHB';

//////////////タイ文字
import ThaiCharacter from './thai_character/HOME_character';
import Consonant from './thai_character/consonant';
import Consonant_Quiz from './thai_character/consonant_quiz';
import Vowel from './thai_character/vowel';
import Vowel_Quiz from './thai_character/vowel_quiz';
import Diphthong from './thai_character/diphthong';
import Diphthong_Quiz from './thai_character/diphthong_quiz';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////タイ文字_JP
import ThaiCharacter_JP from './thai_character_JP/HOME_character';
import Consonant_JP from './thai_character_JP/consonant';
import Consonant_Quiz_JP from './thai_character_JP/consonant_quiz';
import Vowel_JP from './thai_character_JP/vowel';
import Vowel_Quiz_JP from './thai_character_JP/vowel_quiz';
import Diphthong_JP from './thai_character_JP/diphthong';
import Diphthong_Quiz_JP from './thai_character_JP/diphthong_quiz';


//////////////単語
import Words from './words/HOME_words';
import Numbers from './words/numbers';
import TimeHome from './words/timeHome';
import Verb from './words/verb';
import Adjective from './words/adjective';
import Everyday_Life from './words/everyday_life';
import Pronoun from './words/pronoun';
import Question from './words/question';
import Body from './words/body';
import Adverb from './words/adverb';
import Preposition from './words/preposition';
import Conjunction from './words/conjunction';
import Auxiliary_Verb from './words/auxiliary_verb';
import Similar_Verb from './words/similar_verb';
import Places from './words/places';
import House from './words/house';
import Meals from './words/meals';
import Color from './words/color';

//verb 動詞
import Basic_Verb from './words/verb/basic_verb';
import Basic_Verb_Quiz from './words/verb/basic_verb_quiz';
import Basic_Verb_2 from './words/verb/basic_verb_2';
import Basic_Verb_Quiz_2 from './words/verb/basic_verb_quiz_2';
import Basic_Verb_3 from './words/verb/basic_verb_3';
import Basic_Verb_Quiz_3 from './words/verb/basic_verb_quiz_3';
import Basic_Verb_4 from './words/verb/basic_verb_4';
import Basic_Verb_Quiz_4 from './words/verb/basic_verb_quiz_4';
import Basic_Verb_5 from './words/verb/basic_verb_5';
import Basic_Verb_Quiz_5 from './words/verb/basic_verb_quiz_5';
import Basic_Verb_6 from './words/verb/basic_verb_6';
import Basic_Verb_Quiz_6 from './words/verb/basic_verb_quiz_6';
import Daily_Life from './words/verb/daily_life';
import Daily_Life_Quiz from './words/verb/daily_life_quiz';
import Head_Movement from './words/verb/head_movement';
import Head_Movement_Quiz from './words/verb/head_movement_quiz';
import Limbs from './words/verb/limbs';
import Limbs_Quiz from './words/verb/limbs_quiz';

// 形容詞 adjective
import Basic_Adjective from './words/adjective/basic_adjective';
import Basic_Adjective_Quiz from './words/adjective/basic_adjective_quiz';
import Basic_Adjective_2 from './words/adjective/basic_adjective_2';
import Basic_Adjective_Quiz_2 from './words/adjective/basic_adjective_quiz_2';
import Basic_Adjective_3 from './words/adjective/basic_adjective_3';
import Basic_Adjective_Quiz_3 from './words/adjective/basic_adjective_quiz_3';
import Basic_Adjective_4 from './words/adjective/basic_adjective_4';
import Basic_Adjective_Quiz_4 from './words/adjective/basic_adjective_quiz_4';
import Basic_Adjective_5 from './words/adjective/basic_adjective_5';
import Basic_Adjective_Quiz_5 from './words/adjective/basic_adjective_quiz_5';

import Emotion from './words/adjective/emotion';
import Emotion_Quiz from './words/adjective/emotion_quiz';
import Shape from './words/adjective/shape';
import Shape_Quiz from './words/adjective/shape_quiz';
import Situation from './words/adjective/situation';
import Situation_Quiz from './words/adjective/situation_quiz';

// 代名詞 pronoun
import Family from './words/pronoun/family';
import Family_Quiz from './words/pronoun/family';
import People_Around from './words/pronoun/people_around';
import People_Around_Quiz from './words/pronoun/people_around_quiz';
import People_And_Things from './words/pronoun/people_and_things';
import People_And_Things_Quiz from './words/pronoun/people_and_things_quiz';

// 数に関する単語 numbers
import Count_Word from './words/numbers/count_word';
import Count_Word_Quiz from './words/numbers/count_word_quiz';
import Large_Number from './words/numbers/large_number';
import Large_Number_Quiz from './words/numbers/large_number_quiz';
import Other_Numbers from './words/numbers/other_numbers';
import Other_Numbers_Quiz from './words/numbers/other_numbers_quiz';

// 時に関する単語 time
import Date from './words/time/date';
import Date_Quiz from './words/time/date_quiz';
import Month from './words/time/month';
import Month_Quiz from './words/time/month_quiz';
import Time from './words/time/time';
import Time_Quiz from './words/time/time_quiz';
import Time_All_Lower from './words/time/time_all_lower';
import Time_All_Lower_Quiz from './words/time/time_all_lower_quiz';
import Time_For_All_Day from './words/time/time_for_all_day';
import Time_For_All_Day_Quiz from './words/time/time_for_all_day_quiz';
import Today_Etc from './words/time/today_etc';
import Today_Etc_Quiz from './words/time/today_etc_quiz';
import Year_And_Season from './words/time/year_and_season';
import Year_And_Season_Quiz from './words/time/year_and_season_quiz';


// 身体に関する単語 body
import Face from './words/body/face';
import Face_Quiz from './words/body/face_quiz';
import Expression_Of_Body from './words/body/expression_of_body';
import Expression_Of_Body_Quiz from './words/body/expression_of_body_quiz';
import Health from './words/body/health';
import Health_Quiz from './words/body/health_quiz';
import Lower_Body from './words/body/lower_body';
import Lower_Body_Quiz from './words/body/lower_body_quiz';
import Phy_Phenomenon from './words/body/phy_phenomenon';
import Phy_Phenomenon_Quiz from './words/body/phy_phenomenon_quiz';
import Upper_Body from './words/body/upper_body';
import Upper_Body_Quiz from './words/body/upper_body_quiz';

// 食事に関する言葉 meals
import Cooking from './words/meals/cooking';
import Cooking_Quiz from './words/meals/cooking_quiz';
import Daily_Meal from './words/meals/daily_meal';
import Daily_Meal_Quiz from './words/meals/daily_meal_quiz';
import Drink from './words/meals/drink';
import Drink_Quiz from './words/meals/drink_quiz';
import Fruits_Seafood from './words/meals/fruits_seafood';
import Fruits_Seafood_Quiz from './words/meals/fruits_seafood_quiz';
import Kitchen from './words/meals/kitchen';
import Kitchen_Quiz from './words/meals/kitchen_quiz';
import Food_Material from './words/meals/food_material';
import Food_Material_Quiz from './words/meals/food_material_quiz';
import Restraunt from './words/meals/restraunt';
import Restraunt_Quiz from './words/meals/restraunt_quiz';
import Seasoning from './words/meals/seasoning';
import Seasoning_Quiz from './words/meals/seasoning_quiz';

// 日常生活に関する言葉 everyday-life
import Dress_Up from './words/everyday_life/dress_up';
import Dress_Up_Quiz from './words/everyday_life/dress_up_quiz';
import Events_For_A_Lifetime from './words/everyday_life/events_for_a_lifetime';
import Events_For_A_Lifetime_Quiz from './words/everyday_life/events_for_a_lifetime_quiz';
import I_Wear_Clothes from './words/everyday_life/i_wear_clothes';
import I_Wear_Clothes_Quiz from './words/everyday_life/i_wear_clothes_quiz';
import Underwear from './words/everyday_life/underwear';
import Underwear_Quiz from './words/everyday_life/underwear_quiz';
import Weather from './words/everyday_life/weather';
import Weather_Quiz from './words/everyday_life/weather_quiz';

// 助動詞 auxiliary_verb
import Auxiliary_Verb_words from './words/auxiliary_verb/auxiliary_verb';
import Auxiliary_Verb_words_Quiz from './words/auxiliary_verb/auxiliary_verb_quiz';

// 疑問視 question
import Question_words from './words/question/question';
import Question_words_Quiz from './words/question/question_quiz';

// 副詞 adverb
import Adverb_words from './words/adverb/adverb';
import Adverb_words_Quiz from './words/adverb/adverb_quiz';

// 前置詞 preposition
import Preposition_words from './words/preposition/preposition';
import Preposition_words_Quiz from './words/preposition/preposition_quiz';

// 接続詞 conjunction
import Conjunction_words from './words/conjunction/conjunction';
import Conjunction_words_Quiz from './words/conjunction/conjunction_quiz';

// 類別詞 similar-verb
import Similar_Verb_words from './words/similar_verb/similar_verb';
import Similar_Verb_words_Quiz from './words/similar_verb/similar_verb_quiz';

// 場所に関する言葉 places
// 家に関する言葉 house
// 色に関する言葉 color

////////////////////////////////////////////////////////////////////////////////////////////////////////////////単語_JP
import Words_JP from './words_JP/HOME_words';
import Numbers_JP from './words_JP/numbers';
import TimeHome_JP from './words_JP/timeHome';
import Verb_JP from './words_JP/verb';
import Adjective_JP from './words_JP/adjective';
import Everyday_Life_JP from './words_JP/everyday_life';
import Pronoun_JP from './words_JP/pronoun';
import Question_JP from './words_JP/question';
import Body_JP from './words_JP/body';
import Adverb_JP from './words_JP/adverb';
import Preposition_JP from './words_JP/preposition';
import Conjunction_JP from './words_JP/conjunction';
import Auxiliary_Verb_JP from './words_JP/auxiliary_verb';
import Similar_Verb_JP from './words_JP/similar_verb';
import Places_JP from './words_JP/places';
import House_JP from './words_JP/house';
import Meals_JP from './words_JP/meals';
import Color_JP from './words_JP/color';

//verb 動詞
import Basic_Verb_JP from './words_JP/verb/basic_verb';
import Basic_Verb_Quiz_JP from './words_JP/verb/basic_verb_quiz';
import Basic_Verb_2_JP from './words_JP/verb/basic_verb_2';
import Basic_Verb_Quiz_2_JP from './words_JP/verb/basic_verb_quiz_2';
import Basic_Verb_3_JP from './words_JP/verb/basic_verb_3';
import Basic_Verb_Quiz_3_JP from './words_JP/verb/basic_verb_quiz_3';
import Basic_Verb_4_JP from './words_JP/verb/basic_verb_4';
import Basic_Verb_Quiz_4_JP from './words_JP/verb/basic_verb_quiz_4';
import Basic_Verb_5_JP from './words_JP/verb/basic_verb_5';
import Basic_Verb_Quiz_5_JP from './words_JP/verb/basic_verb_quiz_5';
import Basic_Verb_6_JP from './words_JP/verb/basic_verb_6';
import Basic_Verb_Quiz_6_JP from './words_JP/verb/basic_verb_quiz_6';
import Daily_Life_JP from './words_JP/verb/daily_life';
import Daily_Life_Quiz_JP from './words_JP/verb/daily_life_quiz';
import Head_Movement_JP from './words_JP/verb/head_movement';
import Head_Movement_Quiz_JP from './words_JP/verb/head_movement_quiz';
import Limbs_JP from './words_JP/verb/limbs';
import Limbs_Quiz_JP from './words_JP/verb/limbs_quiz';

// 形容詞 adjective
import Basic_Adjective_JP from './words_JP/adjective/basic_adjective';
import Basic_Adjective_Quiz_JP from './words_JP/adjective/basic_adjective_quiz';
import Basic_Adjective_2_JP from './words_JP/adjective/basic_adjective_2';
import Basic_Adjective_Quiz_2_JP from './words_JP/adjective/basic_adjective_quiz_2';
import Basic_Adjective_3_JP from './words_JP/adjective/basic_adjective_3';
import Basic_Adjective_Quiz_3_JP from './words_JP/adjective/basic_adjective_quiz_3';
import Basic_Adjective_4_JP from './words_JP/adjective/basic_adjective_4';
import Basic_Adjective_Quiz_4_JP from './words_JP/adjective/basic_adjective_quiz_4';
import Basic_Adjective_5_JP from './words_JP/adjective/basic_adjective_5';
import Basic_Adjective_Quiz_5_JP from './words_JP/adjective/basic_adjective_quiz_5';

import Emotion_JP from './words_JP/adjective/emotion';
import Emotion_Quiz_JP from './words_JP/adjective/emotion_quiz';
import Shape_JP from './words_JP/adjective/shape';
import Shape_Quiz_JP from './words_JP/adjective/shape_quiz';
import Situation_JP from './words_JP/adjective/situation';
import Situation_Quiz_JP from './words_JP/adjective/situation_quiz';

// 代名詞 pronoun
import Family_JP from './words_JP/pronoun/family';
import Family_Quiz_JP from './words_JP/pronoun/family';
import People_Around_JP from './words_JP/pronoun/people_around';
import People_Around_Quiz_JP from './words_JP/pronoun/people_around_quiz';
import People_And_Things_JP from './words_JP/pronoun/people_and_things';
import People_And_Things_Quiz_JP from './words_JP/pronoun/people_and_things_quiz';

// 数に関する単語 numbers
import Count_Word_JP from './words_JP/numbers/count_word';
import Count_Word_Quiz_JP from './words_JP/numbers/count_word_quiz';
import Large_Number_JP from './words_JP/numbers/large_number';
import Large_Number_Quiz_JP from './words_JP/numbers/large_number_quiz';
import Other_Numbers_JP from './words_JP/numbers/other_numbers';
import Other_Numbers_Quiz_JP from './words_JP/numbers/other_numbers_quiz';

// 時に関する単語 time
import Date_JP from './words_JP/time/date';
import Date_Quiz_JP from './words_JP/time/date_quiz';
import Month_JP from './words_JP/time/month';
import Month_Quiz_JP from './words_JP/time/month_quiz';
import Time_JP from './words_JP/time/time';
import Time_Quiz_JP from './words_JP/time/time_quiz';
import Time_All_Lower_JP from './words_JP/time/time_all_lower';
import Time_All_Lower_Quiz_JP from './words_JP/time/time_all_lower_quiz';
import Time_For_All_Day_JP from './words_JP/time/time_for_all_day';
import Time_For_All_Day_Quiz_JP from './words_JP/time/time_for_all_day_quiz';
import Today_Etc_JP from './words_JP/time/today_etc';
import Today_Etc_Quiz_JP from './words_JP/time/today_etc_quiz';
import Year_And_Season_JP from './words_JP/time/year_and_season';
import Year_And_Season_Quiz_JP from './words_JP/time/year_and_season_quiz';


// 身体に関する単語 body
import Face_JP from './words_JP/body/face';
import Face_Quiz_JP from './words_JP/body/face_quiz';
import Expression_Of_Body_JP from './words_JP/body/expression_of_body';
import Expression_Of_Body_Quiz_JP from './words_JP/body/expression_of_body_quiz';
import Health_JP from './words_JP/body/health';
import Health_Quiz_JP from './words_JP/body/health_quiz';
import Lower_Body_JP from './words_JP/body/lower_body';
import Lower_Body_Quiz_JP from './words_JP/body/lower_body_quiz';
import Phy_Phenomenon_JP from './words_JP/body/phy_phenomenon';
import Phy_Phenomenon_Quiz_JP from './words_JP/body/phy_phenomenon_quiz';
import Upper_Body_JP from './words_JP/body/upper_body';
import Upper_Body_Quiz_JP from './words_JP/body/upper_body_quiz';

// 食事に関する言葉 meals
import Cooking_JP from './words_JP/meals/cooking';
import Cooking_Quiz_JP from './words_JP/meals/cooking_quiz';
import Daily_Meal_JP from './words_JP/meals/daily_meal';
import Daily_Meal_Quiz_JP from './words_JP/meals/daily_meal_quiz';
import Drink_JP from './words_JP/meals/drink';
import Drink_Quiz_JP from './words_JP/meals/drink_quiz';
import Fruits_Seafood_JP from './words_JP/meals/fruits_seafood';
import Fruits_Seafood_Quiz_JP from './words_JP/meals/fruits_seafood_quiz';
import Kitchen_JP from './words_JP/meals/kitchen';
import Kitchen_Quiz_JP from './words_JP/meals/kitchen_quiz';
import Food_Material_JP from './words_JP/meals/food_material';
import Food_Material_Quiz_JP from './words_JP/meals/food_material_quiz';
import Restraunt_JP from './words_JP/meals/restraunt';
import Restraunt_Quiz_JP from './words_JP/meals/restraunt_quiz';
import Seasoning_JP from './words_JP/meals/seasoning';
import Seasoning_Quiz_JP from './words_JP/meals/seasoning_quiz';

// 日常生活に関する言葉 everyday-life
import Dress_Up_JP from './words_JP/everyday_life/dress_up';
import Dress_Up_Quiz_JP from './words_JP/everyday_life/dress_up_quiz';
import Events_For_A_Lifetime_JP from './words_JP/everyday_life/events_for_a_lifetime';
import Events_For_A_Lifetime_Quiz_JP from './words_JP/everyday_life/events_for_a_lifetime_quiz';
import I_Wear_Clothes_JP from './words_JP/everyday_life/i_wear_clothes';
import I_Wear_Clothes_Quiz_JP from './words_JP/everyday_life/i_wear_clothes_quiz';
import Underwear_JP from './words_JP/everyday_life/underwear';
import Underwear_Quiz_JP from './words_JP/everyday_life/underwear_quiz';
import Weather_JP from './words_JP/everyday_life/weather';
import Weather_Quiz_JP from './words_JP/everyday_life/weather_quiz';

// 助動詞 auxiliary_verb
import Auxiliary_Verb_words_JP from './words_JP/auxiliary_verb/auxiliary_verb';
import Auxiliary_Verb_words_Quiz_JP from './words_JP/auxiliary_verb/auxiliary_verb_quiz';

// 疑問視 question
import Question_words_JP from './words_JP/question/question';
import Question_words_Quiz_JP from './words_JP/question/question_quiz';

// 副詞 adverb
import Adverb_words_JP from './words_JP/adverb/adverb';
import Adverb_words_Quiz_JP from './words_JP/adverb/adverb_quiz';

// 前置詞 preposition
import Preposition_words_JP from './words_JP/preposition/preposition';
import Preposition_words_Quiz_JP from './words_JP/preposition/preposition_quiz';

// 接続詞 conjunction
import Conjunction_words_JP from './words_JP/conjunction/conjunction';
import Conjunction_words_Quiz_JP from './words_JP/conjunction/conjunction_quiz';

// 類別詞 similar-verb
import Similar_Verb_words_JP from './words_JP/similar_verb/similar_verb';
import Similar_Verb_words_Quiz_JP from './words_JP/similar_verb/similar_verb_quiz';

// 場所に関する言葉 places
// 家に関する言葉 house
// 色に関する言葉 color

//////////////声調
import Tones from './tones/HOME_tones';
import Tones_Basics from './tones/tones_basics';
import Tones_Mark from './tones/tones_mark';
import Tones_Quiz from './tones/tones_quiz';
import Tones_1 from './tones/tones_1';
import Tones_2 from './tones/tones_2';
import Tones_3 from './tones/tones_3';
import Tones_4 from './tones/tones_4';
import Tones_5 from './tones/tones_5';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////Tones_JP
import Tones_JP from './tones_JP/HOME_tones';
import Tones_Basics_JP from './tones_JP/tones_basics';
import Tones_Mark_JP from './tones_JP/tones_mark';
import Tones_Quiz_JP from './tones_JP/tones_quiz';
import Tones_1_JP from './tones_JP/tones_1';
import Tones_2_JP from './tones_JP/tones_2';
import Tones_3_JP from './tones_JP/tones_3';
import Tones_4_JP from './tones_JP/tones_4';
import Tones_5_JP from './tones_JP/tones_5';


//////////////文法
import Grammar from './grammar/HOME_grammar';
import Grammar_Rules from './grammar/grammar_rules';
import Grammar_Quiz_th from './grammar/grammar_quiz_th';
import Grammar_Quiz_al from './grammar/grammar_quiz_al';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////Grammar_JP
import Grammar_JP from './grammar_JP/HOME_grammar';
import Grammar_Rules_JP from './grammar_JP/grammar_rules';
import Grammar_Quiz_th_JP from './grammar_JP/grammar_quiz_th';
import Grammar_Quiz_al_JP from './grammar_JP/grammar_quiz_al';


//////////////リスニング
import Listening from './listening/HOME_listening';
import Greeting from './listening/greeting';
import Greeting_Quiz from './listening/greeting_quiz';
import Shopping from './listening/shopping';
import Shopping_Quiz from './listening/shopping_quiz';
import Trip from './listening/trip';
import Trip_Quiz from './listening/trip_quiz';
import Restaurant from './listening/restaurant';
import Restaurant_Quiz from './listening/restaurant_quiz';
import ConversationwithFriends from './listening/cwf';
import ConversationwithFriends_Quiz from './listening/cwf_quiz';
import School from './listening/school';
import School_Quiz from './listening/school_quiz';
import Drinkingparty from './listening/drinkingparty';
import Drinkingparty_Quiz from './listening/drinkingparty_quiz';
import Drive from './listening/drive';
import Drive_Quiz from './listening/drive_quiz';
import Loveaffair from './listening/loveaffair';
import Loveaffair_Quiz from './listening/loveaffair_quiz';
import Beach from './listening/beach';
import Beach_Quiz from './listening/beach_quiz';
import Reading_L from './listening/reading';
import Reading_L_Quiz from './listening/reading_quiz';
import Meal from './listening/meal';
import Meal_Quiz from './listening/meal_quiz';
import Music from './listening/music';
import Music_Quiz from './listening/music_quiz';
import Art from './listening/art';
import Art_Quiz from './listening/art_quiz';
import Hospital from './listening/hospital';
import Hospital_Quiz from './listening/hospital_quiz';


////////////////////////////////////////////////////////////////////////////////////////////////////////////////Listening_JP
import Listening_JP from './listening_JP/HOME_listening';
import Greeting_JP from  './listening_JP/greeting';
import Greeting_Quiz_JP from './listening_JP/greeting_quiz';
import Shopping_JP from './listening_JP/shopping';
import Shopping_Quiz_JP from './listening_JP/shopping_quiz';
import Trip_JP from './listening_JP/trip';
import Trip_Quiz_JP from './listening_JP/trip_quiz';
import Restaurant_JP from './listening_JP/restaurant';
import Restaurant_Quiz_JP from './listening_JP/restaurant_quiz';
import ConversationwithFriends_JP from './listening_JP/cwf';
import ConversationwithFriends_Quiz_JP from './listening_JP/cwf_quiz';
import School_JP from './listening_JP/school';
import School_Quiz_JP from './listening_JP/school_quiz';
import Drinkingparty_JP from './listening_JP/drinkingparty';
import Drinkingparty_Quiz_JP from './listening_JP/drinkingparty_quiz';
import Drive_JP from './listening_JP/drive';
import Drive_Quiz_JP from './listening_JP/drive_quiz';
import Loveaffair_JP from './listening_JP/loveaffair';
import Loveaffair_Quiz_JP from './listening_JP/loveaffair_quiz';
import Beach_JP from './listening_JP/beach';
import Beach_Quiz_JP from './listening_JP/beach_quiz';
import Reading_L_JP from './listening_JP/reading';
import Reading_L_Quiz_JP from './listening_JP/reading_quiz';
import Meal_JP from './listening_JP/meal';
import Meal_Quiz_JP from './listening_JP/meal_quiz';
import Music_JP from './listening_JP/music';
import Music_Quiz_JP from './listening_JP/music_quiz';
import Art_JP from './listening_JP/art';
import Art_Quiz_JP from './listening_JP/art_quiz';
import Hospital_JP from './listening_JP/hospital';
import Hospital_Quiz_JP from './listening_JP/hospital_quiz';


//////////////リーディング
import Reading from './reading/HOME_reading';
import Reading_Quiz_1 from './reading/reading_quiz_1';
import Reading_Quiz_2 from './reading/reading_quiz_2';
import Reading_Quiz_3 from './reading/reading_quiz_3';
import Reading_Quiz_4 from './reading/reading_quiz_4';
import Reading_Quiz_5 from './reading/reading_quiz_5';
import Reading_Quiz_6 from './reading/reading_quiz_6';
import Reading_Quiz_7 from './reading/reading_quiz_7';
import Reading_Quiz_8 from './reading/reading_quiz_8';
import Reading_Quiz_9 from './reading/reading_quiz_9';
import Reading_Quiz_10 from './reading/reading_quiz_10';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////Reading_JP
import Reading_JP from './reading_JP/HOME_reading';
import Reading_Quiz_1_JP from './reading_JP/reading_quiz_1';
import Reading_Quiz_2_JP from './reading_JP/reading_quiz_2';
import Reading_Quiz_3_JP from './reading_JP/reading_quiz_3';
import Reading_Quiz_4_JP from './reading_JP/reading_quiz_4';
import Reading_Quiz_5_JP from './reading_JP/reading_quiz_5';
import Reading_Quiz_6_JP from './reading_JP/reading_quiz_6';
import Reading_Quiz_7_JP from './reading_JP/reading_quiz_7';
import Reading_Quiz_8_JP from './reading_JP/reading_quiz_8';
import Reading_Quiz_9_JP from './reading_JP/reading_quiz_9';
import Reading_Quiz_10_JP from './reading_JP/reading_quiz_10';

// ユーザ管理
import Login from './user/login';
import Register from './user/register';
import Activation from './user/activation';
import UserChange from './user/userChange';
import MailChange from './user/mailChange';
import PasswordChange from './user/passwordChange';
import PasswordReset from './user/passwordReset';
import Dashboard from './user/dashboard';
import PrivacyPolicy from './user/privacypolicy';
import Ninearrowsgeeks from './user/ninearrowsgeeks';
import ContactForm from './user/contactForm';
import Index from './user/index';
import Detail from './user/detail'; // 詳細ページのコンポーネント

////////////////////////////////////////////////////////////////////////////////////////////////////////////////User_JP
import Login_JP from './user_JP/login';
import Register_JP from './user_JP/register';
import Activation_JP from './user_JP/activation';
import UserChange_JP from './user_JP/userChange';
import MailChange_JP from './user_JP/mailChange';
import PasswordChange_JP from './user_JP/passwordChange';
import PasswordReset_JP from './user_JP/passwordReset';
import Dashboard_JP from './user_JP/dashboard';
import PrivacyPolicy_JP from './user_JP/privacypolicy';
import Ninearrowsgeeks_JP from './user_JP/ninearrowsgeeks';
import ContactForm_JP from './user_JP/contactForm';
import Index_JP from './user_JP/index';
import Detail_JP from './user_JP/detail'; // 詳細ページのコンポーネント

// モバイルアプリ
import Mobile from './user/mobile';
import Mobile_JP from './user_JP/mobile';

//////////////Not Found
import NotFound from './NotFound';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<Home />} />
        <Route path={`/ja`} element={<Home_JP />} />

      {/* モバイルアプリ */}
        <Route path={`/mobile`} element={<Mobile />} />
        <Route path={`/ja/mobile`} element={<Mobile_JP />} />
 
      {/* タイ文字 */}
        <Route path={`/thai_character`} element={<ThaiCharacter />} />
        <Route path={`/consonant`} element={<Consonant />} />
        <Route path={`/consonant_quiz`} element={<Consonant_Quiz />} />
        <Route path={`/vowel`} element={<Vowel />} />
        <Route path={`/vowel_quiz`} element={<Vowel_Quiz />} />
        <Route path={`/diphthong`} element={<Diphthong />} />
        <Route path={`/diphthong_quiz`} element={<Diphthong_Quiz />} />

      {/* タイ文字 JP **************************************************************************************************/}
      <Route path={`/ja/thai_character`} element={<ThaiCharacter_JP />} />
        <Route path={`/ja/consonant`} element={<Consonant_JP />} />
        <Route path={`/ja/consonant_quiz`} element={<Consonant_Quiz_JP />} />
        <Route path={`/ja/vowel`} element={<Vowel_JP />} />
        <Route path={`/ja/vowel_quiz`} element={<Vowel_Quiz_JP />} />
        <Route path={`/ja/diphthong`} element={<Diphthong_JP />} />
        <Route path={`/ja/diphthong_quiz`} element={<Diphthong_Quiz_JP />} />

       {/* 単語 */} 
        <Route path={`/words`} element={<Words />} />
               {/* 単語Home */}
               <Route path={`/verb`} element={<Verb />} />
               <Route path={`/pronoun`} element={<Pronoun />} />
               <Route path={`/question`} element={<Question />} />
               <Route path={`/numbers`} element={<Numbers />} />
               <Route path={`/timeHome`} element={<TimeHome />} />
               <Route path={`/verb`} element={<Verb />} />
               <Route path={`/body`} element={<Body />} />
               <Route path={`/adverb`} element={<Adverb />} />
               <Route path={`/preposition`} element={<Preposition />} />
               <Route path={`/conjunction`} element={<Conjunction />} />
               <Route path={`/auxiliary_verb`} element={<Auxiliary_Verb />} />
               <Route path={`/similar_verb`} element={<Similar_Verb />} />
               <Route path={`/places`} element={<Places />} />
               <Route path={`/adjective`} element={<Adjective />} />
               <Route path={`/everyday_life`} element={<Everyday_Life />} />
               <Route path={`/house`} element={<House />} />
               <Route path={`/meals`} element={<Meals />} />
               <Route path={`/color`} element={<Color />} />
               {/* 動詞 verb */} 
              <Route path={`/basic_verb`} element={<Basic_Verb />} />
              <Route path={`/basic_verb_quiz`} element={<Basic_Verb_Quiz />} />
              <Route path={`/basic_verb_2`} element={<Basic_Verb_2 />} />
              <Route path={`/basic_verb_quiz_2`} element={<Basic_Verb_Quiz_2 />} />
              <Route path={`/basic_verb_3`} element={<Basic_Verb_3 />} />
              <Route path={`/basic_verb_quiz_3`} element={<Basic_Verb_Quiz_3 />} />
              <Route path={`/basic_verb_4`} element={<Basic_Verb_4 />} />
              <Route path={`/basic_verb_quiz_4`} element={<Basic_Verb_Quiz_4 />} />
              <Route path={`/basic_verb_5`} element={<Basic_Verb_5 />} />
              <Route path={`/basic_verb_quiz_5`} element={<Basic_Verb_Quiz_5 />} />
              <Route path={`/basic_verb_6`} element={<Basic_Verb_6 />} />
              <Route path={`/basic_verb_quiz_6`} element={<Basic_Verb_Quiz_6 />} />
              <Route path={`/daily_life`} element={<Daily_Life />} />
              <Route path={`/daily_life_quiz`} element={<Daily_Life_Quiz />} />
              <Route path={`/Head_Movement`} element={<Head_Movement />} />
              <Route path={`/Head_Movement_quiz`} element={<Head_Movement_Quiz />} />
              <Route path={`/Limbs`} element={<Limbs />} />
              <Route path={`/Limbs_quiz`} element={<Limbs_Quiz />} />

              {/*  形容詞 adjective */} 
              <Route path={`/basic_adjective`} element={<Basic_Adjective />} />
              <Route path={`/basic_adjective_quiz`} element={<Basic_Adjective_Quiz />} />               
              <Route path={`/basic_adjective_2`} element={<Basic_Adjective_2 />} />
              <Route path={`/basic_adjective_quiz_2`} element={<Basic_Adjective_Quiz_2 />} />               
              <Route path={`/basic_adjective_3`} element={<Basic_Adjective_3 />} />
              <Route path={`/basic_adjective_quiz_3`} element={<Basic_Adjective_Quiz_3 />} />               
              <Route path={`/basic_adjective_4`} element={<Basic_Adjective_4 />} />
              <Route path={`/basic_adjective_quiz_4`} element={<Basic_Adjective_Quiz_4 />} />               
              <Route path={`/basic_adjective_5`} element={<Basic_Adjective_5 />} />
              <Route path={`/basic_adjective_quiz_5`} element={<Basic_Adjective_Quiz_5 />} />               

              <Route path={`/emotion`} element={<Emotion />} />
              <Route path={`/emotion_quiz`} element={<Emotion_Quiz />} />               
              <Route path={`/Shape`} element={<Shape />} />
              <Route path={`/Shape_quiz`} element={<Shape_Quiz />} />               
              <Route path={`/Situation`} element={<Situation />} />
              <Route path={`/Situation_quiz`} element={<Situation_Quiz />} />               

              {/*  助動詞 auxiliary_verb */} 
              <Route path={`/Auxiliary_Verb_words`} element={<Auxiliary_Verb_words />} />
              <Route path={`/Auxiliary_Verb_words_quiz`} element={<Auxiliary_Verb_words_Quiz />} />                       

              {/*  疑問視 question */} 
              <Route path={`/Question_words`} element={<Question_words />} />
              <Route path={`/Question_words_quiz`} element={<Question_words_Quiz />} />                       

              {/*  副詞 adverb */} 
              <Route path={`/adverb_words`} element={<Adverb_words />} />
              <Route path={`/adverb_words_quiz`} element={<Adverb_words_Quiz />} />                       

              {/*  前置詞 preposition */} 
              <Route path={`/preposition_words`} element={<Preposition_words />} />
              <Route path={`/preposition_words_quiz`} element={<Preposition_words_Quiz />} />                       

              {/*  接続詞 conjunction */} 
              <Route path={`/conjunction_words`} element={<Conjunction_words />} />
              <Route path={`/conjunction_words_quiz`} element={<Conjunction_words_Quiz />} />                       

              {/*  類別詞 similar-verb */} 
              <Route path={`/similar_verb_words`} element={<Similar_Verb_words />} />
              <Route path={`/similar_verb_words_quiz`} element={<Similar_Verb_words_Quiz />} />                       

              {/*  代名詞に関する単語 pronoun */} 
              <Route path={`/Family`} element={<Family />} />
              <Route path={`/Family_quiz`} element={<Family_Quiz />} />                       
              <Route path={`/People_Around`} element={<People_Around />} />
              <Route path={`/People_Around_quiz`} element={<People_Around_Quiz />} />                       
              <Route path={`/People_And_Things`} element={<People_And_Things />} />
              <Route path={`/People_And_Things_quiz`} element={<People_And_Things_Quiz />} />                       

              {/*  時に関する単語 time */} 
              <Route path={`/date`} element={<Date />} />
              <Route path={`/date_quiz`} element={<Date_Quiz />} />                       
              <Route path={`/Month`} element={<Month />} />
              <Route path={`/Month_quiz`} element={<Month_Quiz />} />                       
              <Route path={`/Time`} element={<Time />} />
              <Route path={`/Time_quiz`} element={<Time_Quiz />} />                       
              <Route path={`/Time_All_Lower`} element={<Time_All_Lower />} />
              <Route path={`/Time_All_Lower_quiz`} element={<Time_All_Lower_Quiz />} />                       
              <Route path={`/Time_For_All_Day`} element={<Time_For_All_Day />} />
              <Route path={`/Time_For_All_Day_quiz`} element={<Time_For_All_Day_Quiz />} />                       
              <Route path={`/Today_Etc`} element={<Today_Etc />} />
              <Route path={`/Today_Etc_quiz`} element={<Today_Etc_Quiz />} />                       
              <Route path={`/Year_And_Season`} element={<Year_And_Season />} />
              <Route path={`/Year_And_Season_quiz`} element={<Year_And_Season_Quiz />} />                       

              {/*  数に関する単語 numbers */} 
              <Route path={`/count_word`} element={<Count_Word />} />
              <Route path={`/count_word_quiz`} element={<Count_Word_Quiz />} />                      
              <Route path={`/Large_Number`} element={<Large_Number />} />
              <Route path={`/Large_Number_quiz`} element={<Large_Number_Quiz />} />                      
              <Route path={`/Other_Numbers`} element={<Other_Numbers />} />
              <Route path={`/Other_Numbers_quiz`} element={<Other_Numbers_Quiz />} />                      
              {/*  身体に関する単語 time */} 
              <Route path={`/face`} element={<Face />} />
              <Route path={`/face_quiz`} element={<Face_Quiz />} />                       
              <Route path={`/Expression_Of_Body`} element={<Expression_Of_Body />} />
              <Route path={`/Expression_Of_Body_quiz`} element={<Expression_Of_Body_Quiz />} />                       
              <Route path={`/Health`} element={<Health />} />
              <Route path={`/Health_quiz`} element={<Health_Quiz />} />                       
              <Route path={`/Lower_Body`} element={<Lower_Body />} />
              <Route path={`/Lower_Body_quiz`} element={<Lower_Body_Quiz />} />                       
              <Route path={`/Phy_Phenomenon`} element={<Phy_Phenomenon />} />
              <Route path={`/Phy_Phenomenon_quiz`} element={<Phy_Phenomenon_Quiz />} />                       
              <Route path={`/Upper_Body`} element={<Upper_Body />} />
              <Route path={`/Upper_Body_quiz`} element={<Upper_Body_Quiz />} />                       

              {/* 食事に関する言葉 meals */} 
              <Route path={`/Cooking`} element={<Cooking />} />
              <Route path={`/Cooking_quiz`} element={<Cooking_Quiz />} />                       
              <Route path={`/Daily_Meal`} element={<Daily_Meal />} />
              <Route path={`/Daily_Meal_quiz`} element={<Daily_Meal_Quiz />} />                       
              <Route path={`/Drink`} element={<Drink/>} />
              <Route path={`/Drink_quiz`} element={<Drink_Quiz />} />                       
              <Route path={`/Fruits_Seafood`} element={<Fruits_Seafood />} />
              <Route path={`/Fruits_Seafood_quiz`} element={<Fruits_Seafood_Quiz />} />                       
              <Route path={`/Kitchen`} element={<Kitchen />} />
              <Route path={`/Kitchen_quiz`} element={<Kitchen_Quiz />} />                       
              <Route path={`/Food_Material`} element={<Food_Material />} />
              <Route path={`/Food_Material_quiz`} element={<Food_Material_Quiz />} />                       
              <Route path={`/Restraunt`} element={<Restraunt />} />
              <Route path={`/Restraunt_quiz`} element={<Restraunt_Quiz />} />                       
              <Route path={`/Seasoning`} element={<Seasoning />} />
              <Route path={`/Seasoning_quiz`} element={<Seasoning_Quiz />} />                       

              {/* 日常生活に関する言葉 everyday-life */} 
              <Route path={`/dress_up`} element={<Dress_Up />} />
              <Route path={`/dress_up_quiz`} element={<Dress_Up_Quiz />} />
              <Route path={`/events_for_a_lifetime`} element={<Events_For_A_Lifetime />} />
              <Route path={`/events_for_a_lifetime_quiz`} element={<Events_For_A_Lifetime_Quiz />} />
              <Route path={`/I_Wear_Clothes`} element={<I_Wear_Clothes />} />
              <Route path={`/I_Wear_Clothes_quiz`} element={<I_Wear_Clothes_Quiz />} />
              <Route path={`/Underwear`} element={<Underwear />} />
              <Route path={`/Underwear_quiz`} element={<Underwear_Quiz />} />
              <Route path={`/Weather`} element={<Weather />} />
              <Route path={`/Weather_quiz`} element={<Weather_Quiz />} />

       {/* 単語 JP **************************************************************************************************/} 
      <Route path={`/ja/words`} element={<Words_JP />} />
               {/* 単語Home */}
               <Route path={`/ja/verb`} element={<Verb_JP />} />
               <Route path={`/ja/pronoun`} element={<Pronoun_JP />} />
               <Route path={`/ja/question`} element={<Question_JP />} />
               <Route path={`/ja/numbers`} element={<Numbers_JP />} />
               <Route path={`/ja/timeHome`} element={<TimeHome_JP />} />
               <Route path={`/ja/verb`} element={<Verb_JP />} />
               <Route path={`/ja/body`} element={<Body_JP />} />
               <Route path={`/ja/adverb`} element={<Adverb_JP />} />
               <Route path={`/ja/preposition`} element={<Preposition_JP />} />
               <Route path={`/ja/conjunction`} element={<Conjunction_JP />} />
               <Route path={`/ja/auxiliary_verb`} element={<Auxiliary_Verb_JP />} />
               <Route path={`/ja/similar_verb`} element={<Similar_Verb_JP />} />
               <Route path={`/ja/places`} element={<Places_JP />} />
               <Route path={`/ja/adjective`} element={<Adjective_JP />} />
               <Route path={`/ja/everyday_life`} element={<Everyday_Life_JP />} />
               <Route path={`/ja/house`} element={<House_JP />} />
               <Route path={`/ja/meals`} element={<Meals_JP />} />
               <Route path={`/ja/color`} element={<Color_JP />} />
               {/* 動詞 verb */} 
              <Route path={`/ja/basic_verb`} element={<Basic_Verb_JP />} />
              <Route path={`/ja/basic_verb_quiz`} element={<Basic_Verb_Quiz_JP />} />
              <Route path={`/ja/basic_verb_2`} element={<Basic_Verb_2_JP />} />
              <Route path={`/ja/basic_verb_quiz_2`} element={<Basic_Verb_Quiz_2_JP />} />
              <Route path={`/ja/basic_verb_3`} element={<Basic_Verb_3_JP />} />
              <Route path={`/ja/basic_verb_quiz_3`} element={<Basic_Verb_Quiz_3_JP />} />
              <Route path={`/ja/basic_verb_4`} element={<Basic_Verb_4_JP />} />
              <Route path={`/ja/basic_verb_quiz_4`} element={<Basic_Verb_Quiz_4_JP />} />
              <Route path={`/ja/basic_verb_5`} element={<Basic_Verb_5_JP />} />
              <Route path={`/ja/basic_verb_quiz_5`} element={<Basic_Verb_Quiz_5_JP />} />
              <Route path={`/ja/basic_verb_6`} element={<Basic_Verb_6_JP />} />
              <Route path={`/ja/basic_verb_quiz_6`} element={<Basic_Verb_Quiz_6_JP />} />
              <Route path={`/ja/daily_life`} element={<Daily_Life_JP />} />
              <Route path={`/ja/daily_life_quiz`} element={<Daily_Life_Quiz_JP />} />
              <Route path={`/ja/Head_Movement`} element={<Head_Movement_JP />} />
              <Route path={`/ja/Head_Movement_quiz`} element={<Head_Movement_Quiz_JP />} />
              <Route path={`/ja/Limbs`} element={<Limbs_JP />} />
              <Route path={`/ja/Limbs_quiz`} element={<Limbs_Quiz_JP />} />

              {/*  形容詞 adjective */} 
              <Route path={`/ja/basic_adjective`} element={<Basic_Adjective_JP />} />
              <Route path={`/ja/basic_adjective_quiz`} element={<Basic_Adjective_Quiz_JP />} />               
              <Route path={`/ja/basic_adjective_2`} element={<Basic_Adjective_2_JP />} />
              <Route path={`/ja/basic_adjective_quiz_2`} element={<Basic_Adjective_Quiz_2_JP />} />               
              <Route path={`/ja/basic_adjective_3`} element={<Basic_Adjective_3_JP />} />
              <Route path={`/ja/basic_adjective_quiz_3`} element={<Basic_Adjective_Quiz_3_JP />} />               
              <Route path={`/ja/basic_adjective_4`} element={<Basic_Adjective_4_JP />} />
              <Route path={`/ja/basic_adjective_quiz_4`} element={<Basic_Adjective_Quiz_4_JP />} />               
              <Route path={`/ja/basic_adjective_5`} element={<Basic_Adjective_5_JP />} />
              <Route path={`/ja/basic_adjective_quiz_5`} element={<Basic_Adjective_Quiz_5_JP />} />               

              <Route path={`/ja/emotion`} element={<Emotion_JP />} />
              <Route path={`/ja/emotion_quiz`} element={<Emotion_Quiz_JP />} />               
              <Route path={`/ja/Shape`} element={<Shape_JP />} />
              <Route path={`/ja/Shape_quiz`} element={<Shape_Quiz_JP />} />               
              <Route path={`/ja/Situation`} element={<Situation_JP />} />
              <Route path={`/ja/Situation_quiz`} element={<Situation_Quiz_JP />} />               

              {/*  助動詞 auxiliary_verb */} 
              <Route path={`/ja/Auxiliary_Verb_words`} element={<Auxiliary_Verb_words_JP />} />
              <Route path={`/ja/Auxiliary_Verb_words_quiz`} element={<Auxiliary_Verb_words_Quiz_JP />} />                       

              {/*  疑問視 question */} 
              <Route path={`/ja/Question_words`} element={<Question_words_JP />} />
              <Route path={`/ja/Question_words_quiz`} element={<Question_words_Quiz_JP />} />                       

              {/*  副詞 adverb */} 
              <Route path={`/ja/adverb_words`} element={<Adverb_words_JP />} />
              <Route path={`/ja/adverb_words_quiz`} element={<Adverb_words_Quiz_JP />} />                       

              {/*  前置詞 preposition */} 
              <Route path={`/ja/preposition_words`} element={<Preposition_words_JP />} />
              <Route path={`/ja/preposition_words_quiz`} element={<Preposition_words_Quiz_JP />} />                       

              {/*  接続詞 conjunction */} 
              <Route path={`/ja/conjunction_words`} element={<Conjunction_words_JP />} />
              <Route path={`/ja/conjunction_words_quiz`} element={<Conjunction_words_Quiz_JP />} />                       

              {/*  類別詞 similar-verb */} 
              <Route path={`/ja/similar_verb_words`} element={<Similar_Verb_words_JP />} />
              <Route path={`/ja/similar_verb_words_quiz`} element={<Similar_Verb_words_Quiz_JP />} />                       

              {/*  代名詞に関する単語 pronoun */} 
              <Route path={`/ja/Family`} element={<Family_JP />} />
              <Route path={`/ja/Family_quiz`} element={<Family_Quiz_JP />} />                       
              <Route path={`/ja/People_Around`} element={<People_Around_JP />} />
              <Route path={`/ja/People_Around_quiz`} element={<People_Around_Quiz_JP />} />                       
              <Route path={`/ja/People_And_Things`} element={<People_And_Things_JP />} />
              <Route path={`/ja/People_And_Things_quiz`} element={<People_And_Things_Quiz_JP />} />                       

              {/*  時に関する単語 time */} 
              <Route path={`/ja/date`} element={<Date_JP />} />
              <Route path={`/ja/date_quiz`} element={<Date_Quiz_JP />} />                       
              <Route path={`/ja/Month`} element={<Month_JP />} />
              <Route path={`/ja/Month_quiz`} element={<Month_Quiz_JP />} />                       
              <Route path={`/ja/Time`} element={<Time_JP />} />
              <Route path={`/ja/Time_quiz`} element={<Time_Quiz_JP />} />                       
              <Route path={`/ja/Time_All_Lower`} element={<Time_All_Lower_JP />} />
              <Route path={`/ja/Time_All_Lower_quiz`} element={<Time_All_Lower_Quiz_JP />} />                       
              <Route path={`/ja/Time_For_All_Day`} element={<Time_For_All_Day_JP />} />
              <Route path={`/ja/Time_For_All_Day_quiz`} element={<Time_For_All_Day_Quiz_JP />} />                       
              <Route path={`/ja/Today_Etc`} element={<Today_Etc_JP />} />
              <Route path={`/ja/Today_Etc_quiz`} element={<Today_Etc_Quiz_JP />} />                       
              <Route path={`/ja/Year_And_Season`} element={<Year_And_Season_JP />} />
              <Route path={`/ja/Year_And_Season_quiz`} element={<Year_And_Season_Quiz_JP />} />                       

              {/*  数に関する単語 numbers */} 
              <Route path={`/ja/count_word`} element={<Count_Word_JP />} />
              <Route path={`/ja/count_word_quiz`} element={<Count_Word_Quiz_JP />} />                      
              <Route path={`/ja/Large_Number`} element={<Large_Number_JP />} />
              <Route path={`/ja/Large_Number_quiz`} element={<Large_Number_Quiz_JP />} />                      
              <Route path={`/ja/Other_Numbers`} element={<Other_Numbers_JP />} />
              <Route path={`/ja/Other_Numbers_quiz`} element={<Other_Numbers_Quiz_JP />} />                      
              {/*  身体に関する単語 time */} 
              <Route path={`/ja/face`} element={<Face_JP />} />
              <Route path={`/ja/face_quiz`} element={<Face_Quiz_JP />} />                       
              <Route path={`/ja/Expression_Of_Body`} element={<Expression_Of_Body_JP />} />
              <Route path={`/ja/Expression_Of_Body_quiz`} element={<Expression_Of_Body_Quiz_JP />} />                       
              <Route path={`/ja/Health`} element={<Health_JP />} />
              <Route path={`/ja/Health_quiz`} element={<Health_Quiz_JP />} />                       
              <Route path={`/ja/Lower_Body`} element={<Lower_Body_JP />} />
              <Route path={`/ja/Lower_Body_quiz`} element={<Lower_Body_Quiz_JP />} />                       
              <Route path={`/ja/Phy_Phenomenon`} element={<Phy_Phenomenon_JP />} />
              <Route path={`/ja/Phy_Phenomenon_quiz`} element={<Phy_Phenomenon_Quiz_JP />} />                       
              <Route path={`/ja/Upper_Body`} element={<Upper_Body_JP />} />
              <Route path={`/ja/Upper_Body_quiz`} element={<Upper_Body_Quiz_JP />} />                       

              {/* 食事に関する言葉 meals */} 
              <Route path={`/ja/Cooking`} element={<Cooking_JP />} />
              <Route path={`/ja/Cooking_quiz`} element={<Cooking_Quiz_JP />} />                       
              <Route path={`/ja/Daily_Meal`} element={<Daily_Meal_JP />} />
              <Route path={`/ja/Daily_Meal_quiz`} element={<Daily_Meal_Quiz_JP />} />                       
              <Route path={`/ja/Drink`} element={<Drink_JP/>} />
              <Route path={`/ja/Drink_quiz`} element={<Drink_Quiz_JP />} />                       
              <Route path={`/ja/Fruits_Seafood`} element={<Fruits_Seafood_JP />} />
              <Route path={`/ja/Fruits_Seafood_quiz`} element={<Fruits_Seafood_Quiz_JP />} />                       
              <Route path={`/ja/Kitchen`} element={<Kitchen_JP />} />
              <Route path={`/ja/Kitchen_quiz`} element={<Kitchen_Quiz_JP />} />                       
              <Route path={`/ja/Food_Material`} element={<Food_Material_JP />} />
              <Route path={`/ja/Food_Material_quiz`} element={<Food_Material_Quiz_JP />} />                       
              <Route path={`/ja/Restraunt`} element={<Restraunt_JP />} />
              <Route path={`/ja/Restraunt_quiz`} element={<Restraunt_Quiz_JP />} />                       
              <Route path={`/ja/Seasoning`} element={<Seasoning_JP />} />
              <Route path={`/ja/Seasoning_quiz`} element={<Seasoning_Quiz_JP />} />                       

              {/* 日常生活に関する言葉 everyday-life */} 
              <Route path={`/ja/dress_up`} element={<Dress_Up_JP />} />
              <Route path={`/ja/dress_up_quiz`} element={<Dress_Up_Quiz_JP />} />
              <Route path={`/ja/events_for_a_lifetime`} element={<Events_For_A_Lifetime_JP />} />
              <Route path={`/ja/events_for_a_lifetime_quiz`} element={<Events_For_A_Lifetime_Quiz_JP />} />
              <Route path={`/ja/I_Wear_Clothes`} element={<I_Wear_Clothes_JP />} />
              <Route path={`/ja/I_Wear_Clothes_quiz`} element={<I_Wear_Clothes_Quiz_JP />} />
              <Route path={`/ja/Underwear`} element={<Underwear_JP />} />
              <Route path={`/ja/Underwear_quiz`} element={<Underwear_Quiz_JP />} />
              <Route path={`/ja/Weather`} element={<Weather_JP />} />
              <Route path={`/ja/Weather_quiz`} element={<Weather_Quiz_JP />} />

       {/* 声調 */} 
        <Route path={`/tones`} element={<Tones />} />
        <Route path={`/tones_basics`} element={<Tones_Basics />} />
        <Route path={`/tones_mark`} element={<Tones_Mark />} />
        <Route path={`/tones_quiz`} element={<Tones_Quiz />} />
        <Route path={`/tones_1`} element={<Tones_1 />} />
        <Route path={`/tones_2`} element={<Tones_2 />} />
        <Route path={`/tones_3`} element={<Tones_3 />} />
        <Route path={`/tones_4`} element={<Tones_4 />} />
        <Route path={`/tones_5`} element={<Tones_5 />} />

       {/* 声調 JP**************************************************************************************************/} 
       <Route path={`/ja/tones`} element={<Tones_JP />} />
        <Route path={`/ja/tones_basics`} element={<Tones_Basics_JP />} />
        <Route path={`/ja/tones_mark`} element={<Tones_Mark_JP />} />
        <Route path={`/ja/tones_quiz`} element={<Tones_Quiz_JP />} />
        <Route path={`/ja/tones_1`} element={<Tones_1_JP />} />
        <Route path={`/ja/tones_2`} element={<Tones_2_JP />} />
        <Route path={`/ja/tones_3`} element={<Tones_3_JP />} />
        <Route path={`/ja/tones_4`} element={<Tones_4_JP />} />
        <Route path={`/ja/tones_5`} element={<Tones_5_JP />} />

       {/* 文法 */} 
       <Route path={`/grammar`} element={<Grammar />} />
        <Route path={`/grammar_rules`} element={<Grammar_Rules />} />
        <Route path={`/grammar_quiz_al`} element={<Grammar_Quiz_al />} />
        <Route path={`/grammar_quiz_th`} element={<Grammar_Quiz_th />} />

       {/* 文法 JP**************************************************************************************************/} 
        <Route path={`/ja/grammar`} element={<Grammar_JP />} />
        <Route path={`/ja/grammar_rules`} element={<Grammar_Rules_JP />} />
        <Route path={`/ja/grammar_quiz_al`} element={<Grammar_Quiz_al_JP />} />
        <Route path={`/ja/grammar_quiz_th`} element={<Grammar_Quiz_th_JP />} />

      {/* リスニング */}         
      <Route path={`/listening`} element={<Listening />} />
      <Route path={`/greeting`} element={<Greeting />} />
      <Route path={`/greeting_quiz`} element={<Greeting_Quiz />} />
      <Route path={`/shopping`} element={<Shopping />} />
      <Route path={`/shopping_quiz`} element={<Shopping_Quiz />} />
      <Route path={`/trip`} element={<Trip />} />
      <Route path={`/trip_quiz`} element={<Trip_Quiz />} />
      <Route path={`/restaurant`} element={<Restaurant />} />
      <Route path={`/restaurant_quiz`} element={<Restaurant_Quiz />} />
      <Route path={`/conversationwithfriends`} element={<ConversationwithFriends />} />
      <Route path={`/conversationwithfriends_quiz`} element={<ConversationwithFriends_Quiz />} />
      <Route path={`/school`} element={<School />} />
      <Route path={`/school_quiz`} element={<School_Quiz />} />
      <Route path={`/drinkingparty`} element={<Drinkingparty />} />
      <Route path={`/drinkingparty_quiz`} element={<Drinkingparty_Quiz />} />
      <Route path={`/drive`} element={<Drive />} />
      <Route path={`/drive_quiz`} element={<Drive_Quiz />} />
      <Route path={`/loveaffair`} element={<Loveaffair />} />
      <Route path={`/loveaffair_quiz`} element={<Loveaffair_Quiz />} />
      <Route path={`/beach`} element={<Beach />} />
      <Route path={`/beach_quiz`} element={<Beach_Quiz />} />
      <Route path={`/reading_l`} element={<Reading_L />} />
      <Route path={`/reading_l_quiz`} element={<Reading_L_Quiz />} />
      <Route path={`/meal`} element={<Meal />} />
      <Route path={`/meal_quiz`} element={<Meal_Quiz />} />
      <Route path={`/music`} element={<Music />} />
      <Route path={`/music_quiz`} element={<Music_Quiz />} />
      <Route path={`/art`} element={<Art />} />
      <Route path={`/art_quiz`} element={<Art_Quiz />} />
      <Route path={`/hospital`} element={<Hospital />} />
      <Route path={`/hospital_quiz`} element={<Hospital_Quiz />} />

       {/* リスニング JP**************************************************************************************************/} 
       <Route path={`/ja/listening`} element={<Listening_JP />} />
      <Route path={`/ja/greeting`} element={<Greeting_JP />} />
      <Route path={`/ja/greeting`} element={<Greeting_JP />} />
      <Route path={`/ja/greeting_quiz`} element={<Greeting_Quiz_JP />} />
      <Route path={`/ja/shopping`} element={<Shopping_JP />} />
      <Route path={`/ja/shopping_quiz`} element={<Shopping_Quiz_JP />} />
      <Route path={`/ja/trip`} element={<Trip_JP />} />
      <Route path={`/ja/trip_quiz`} element={<Trip_Quiz_JP />} />
      <Route path={`/ja/restaurant`} element={<Restaurant_JP />} />
      <Route path={`/ja/restaurant_quiz`} element={<Restaurant_Quiz_JP />} />
      <Route path={`/ja/conversationwithfriends`} element={<ConversationwithFriends_JP />} />
      <Route path={`/ja/conversationwithfriends_quiz`} element={<ConversationwithFriends_Quiz_JP />} />
      <Route path={`/ja/school`} element={<School_JP />} />
      <Route path={`/ja/school_quiz`} element={<School_Quiz_JP />} />
      <Route path={`/ja/drinkingparty`} element={<Drinkingparty_JP />} />
      <Route path={`/ja/drinkingparty_quiz`} element={<Drinkingparty_Quiz_JP />} />
      <Route path={`/ja/drive`} element={<Drive_JP />} />
      <Route path={`/ja/drive_quiz`} element={<Drive_Quiz_JP />} />
      <Route path={`/ja/loveaffair`} element={<Loveaffair_JP />} />
      <Route path={`/ja/loveaffair_quiz`} element={<Loveaffair_Quiz_JP />} />
      <Route path={`/ja/beach`} element={<Beach_JP />} />
      <Route path={`/ja/beach_quiz`} element={<Beach_Quiz_JP />} />
      <Route path={`/ja/reading_l`} element={<Reading_L_JP />} />
      <Route path={`/ja/reading_l_quiz`} element={<Reading_L_Quiz_JP />} />
      <Route path={`/ja/meal`} element={<Meal_JP />} />
      <Route path={`/ja/meal_quiz`} element={<Meal_Quiz_JP />} />
      <Route path={`/ja/music`} element={<Music_JP />} />
      <Route path={`/ja/music_quiz`} element={<Music_Quiz_JP />} />
      <Route path={`/ja/art`} element={<Art_JP />} />
      <Route path={`/ja/art_quiz`} element={<Art_Quiz_JP />} />
      <Route path={`/ja/hospital`} element={<Hospital_JP />} />
      <Route path={`/ja/hospital_quiz`} element={<Hospital_Quiz_JP />} />


       {/* リーディング */} 
        <Route path={`/reading`} element={<Reading />} />
        <Route path={`/reading_quiz_1`} element={<Reading_Quiz_1 />} />
        <Route path={`/reading_quiz_2`} element={<Reading_Quiz_2 />} />
        <Route path={`/reading_quiz_3`} element={<Reading_Quiz_3 />} />
        <Route path={`/reading_quiz_4`} element={<Reading_Quiz_4 />} />
        <Route path={`/reading_quiz_5`} element={<Reading_Quiz_5 />} />
        <Route path={`/reading_quiz_6`} element={<Reading_Quiz_6 />} />
        <Route path={`/reading_quiz_7`} element={<Reading_Quiz_7 />} />
        <Route path={`/reading_quiz_8`} element={<Reading_Quiz_8 />} />
        <Route path={`/reading_quiz_9`} element={<Reading_Quiz_9 />} />
        <Route path={`/reading_quiz_10`} element={<Reading_Quiz_10 />} />

       {/* リ-ディング JP**************************************************************************************************/} 
       <Route path={`/ja/reading`} element={<Reading_JP />} />
        <Route path={`/ja/reading_quiz_1`} element={<Reading_Quiz_1_JP />} />
        <Route path={`/ja/reading_quiz_2`} element={<Reading_Quiz_2_JP />} />
        <Route path={`/ja/reading_quiz_3`} element={<Reading_Quiz_3_JP />} />
        <Route path={`/ja/reading_quiz_4`} element={<Reading_Quiz_4_JP />} />
        <Route path={`/ja/reading_quiz_5`} element={<Reading_Quiz_5_JP />} />
        <Route path={`/ja/reading_quiz_6`} element={<Reading_Quiz_6_JP />} />
        <Route path={`/ja/reading_quiz_7`} element={<Reading_Quiz_7_JP />} />
        <Route path={`/ja/reading_quiz_8`} element={<Reading_Quiz_8_JP />} />
        <Route path={`/ja/reading_quiz_9`} element={<Reading_Quiz_9_JP />} />
        <Route path={`/ja/reading_quiz_10`} element={<Reading_Quiz_10_JP />} />

      {/* ユーザ管理 */}
      <Route path={`/login`} element={<Login />} />
      <Route path={`/register`} element={<Register />} />
      <Route path={`/userChange`} element={<UserChange />} />
      <Route path={`/activate/:uid/:token`} element={<Activation />} />
      <Route path={`/email/reset/confirm/:uid/:token`} element={<MailChange />} />
      <Route path={`/passwordChange`} element={<PasswordChange />} />
      <Route path={`/password/reset/confirm/:uid/:token`} element={<PasswordReset />} />
      <Route path={`/dashboard`} element={<Dashboard />} />
      <Route path={`/privacypolicy`} element={<PrivacyPolicy />} />
      <Route path={`/ninearrowsgeeks`} element={<Ninearrowsgeeks />} />
      <Route path={`/contactform`} element={<ContactForm />} />
      <Route path={`/index`} element={<Index />} />
      <Route path="/detail/:id" element={<Detail />} />

       {/* User JP**************************************************************************************************/} 
      <Route path={`/ja/login`} element={<Login_JP />} />
      <Route path={`/ja/register`} element={<Register_JP />} />
      <Route path={`/ja/userChange`} element={<UserChange_JP />} />
      <Route path={`/ja/activate/:uid/:token`} element={<Activation_JP />} />
      <Route path={`/ja/email/reset/confirm/:uid/:token`} element={<MailChange_JP />} />
      <Route path={`/ja/passwordChange`} element={<PasswordChange_JP />} />
      <Route path={`/ja/password/reset/confirm/:uid/:token`} element={<PasswordReset_JP />} />
      <Route path={`/ja/dashboard`} element={<Dashboard_JP />} />
      <Route path={`/ja/privacypolicy`} element={<PrivacyPolicy_JP />} />
      <Route path={`/ja/ninearrowsgeeks`} element={<Ninearrowsgeeks_JP />} />
      <Route path={`/ja/contactform`} element={<ContactForm_JP />} />
      <Route path={`/ja/index`} element={<Index_JP />} />
      <Route path="/ja/detail/:id" element={<Detail_JP />} />


        {/* 見つからなかった場合 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;