import React, {useState,useEffect} from 'react'
import '../App.css';
import { Link } from "react-router-dom";

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';
import '../App.css';

const Words = () => {
  useEffect(() => {
    document.title = 'タイ語 動詞';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    // スクロールイベントリスナーを追加
    const handleScroll = () => {
      // スクロール位置を取得
      const scrollY = window.scrollY;

      // スクロール位置が1000pxを超えた場合にフッターを非表示にする
      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <html>
      <body>
      <Header />
      <Banner className="words-page"/>
  
      <div className="center-thai">
        <h1 className="title-each-page">動詞</h1>
        <div className="button-group">
          <Link to="/ja/basic_verb" className="button">基本動詞①</Link>
          <Link to="/ja/basic_verb_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/basic_verb_2" className="button">基本動詞②</Link>
          <Link to="/ja/basic_verb_quiz_2" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/basic_verb_3" className="button">基本動詞③</Link>
          <Link to="/ja/basic_verb_quiz_3" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/basic_verb_4" className="button">基本動詞④</Link>
          <Link to="/ja/basic_verb_quiz_4" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/basic_verb_5" className="button">基本動詞⑤</Link>
          <Link to="/ja/basic_verb_quiz_5" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/basic_verb_6" className="button">基本動詞⑥</Link>
          <Link to="/ja/basic_verb_quiz_6" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/daily_life" className="button">日常生活</Link>
          <Link to="/ja/daily_life_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/head_movement" className="button">頭の動作</Link>
          <Link to="/ja/head_movement_quiz" className="button">確認クイズ</Link>
        </div>
        <div className="button-group">
          <Link to="/ja/limbs" className="button">手足の動作</Link>
          <Link to="/ja/limbs_quiz" className="button">確認クイズ</Link>
        </div>

        <div className="button-group-home">
        <Link to="/ja/words" className="button">戻る</Link>
        </div>
  
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}

      </body>
      </html>
    </>
    );
  };

export default Words;