import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link,useParams } from 'react-router-dom';
import axios from 'axios';

import Header from '../header_JP';
import Banner from '../banner';
import Footer from '../footer';

const Login = () => {
  useEffect(() => {
    document.title = 'ユーザ本登録';
  }, []);

  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 30) {
        setShouldHideFooter(true);
      } else {
        setShouldHideFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { uid, token } = useParams();
  const [activationStatus, setActivationStatus] = useState(''); // アクティベーションのステータスを保持するステート

  const handleActivation = () => {
    // アクティベーションリクエストを送信
    axios
      .post(
        //local
        // 'http://localhost:8000/api/auth/users/activation/',
        //production
        'https://bitmerica.net/api/auth/users/activation/',        
        {
          uid: uid,
          token: token,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        // アクティベーション成功時の処理
        setActivationStatus('本登録が成功しました');
      })
      .catch((error) => {
        // アクティベーションエラー時の処理
        setActivationStatus('本登録でエラーが発生しました');
      });
  };

  useEffect(() => {
    // uidとtokenを使用してアクティベーションのロジックを実行
    handleActivation();
  }, [uid, token]);

  return (
    <>
      <Header />
      <Banner className="user-page" />

    <div className="input-container">
      <h1>ユーザ本登録ページ</h1>
      <p>{activationStatus}</p>
    </div>

      <div className="center-thai">
        <div className="button-group-home">
          <Link to="/ja" className="button">HOME</Link>
        </div>
      </div>
      {shouldHideFooter ? null : <Footer className="custom-footer" />}
    </>
  );
};

export default Login;
