// import React from 'react';

// const Banner = () => {
//   return (
//     <div className="home-page">
//       <div className="index-page-content">
//       </div>
//     </div>
//   );
// }

// export default Banner;

import React from 'react';

const Banner = ({ className }) => {
  return (
    <div className={className}>
      {/* Banner コンポーネントの内容 */}
    </div>
  );
}

export default Banner;
